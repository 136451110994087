/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 125% / 1.4 $font-seravek;
  overflow-x:hidden;
  background: $color-background;
}
p{
  font-family: $font-seravek;
  font-weight: normal;
  font-size: 14px;
}

.color-orange{
  color: $orange;
}

h1, h2, h3, h4, h5, h6{
	font-family: $font-graphik;
}
