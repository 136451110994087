/**
 * Form
 * --------------------------------------------------
 * A custom style for form and it's components.
 */

label{
    color: $color-darker;
    font-size: 15px;
    font-weight: $font-medium;
    display: block;
    margin-bottom: 10px;
}
input[type=text],
input[type=email],
input[type=password],
input[type=number],
select,
textarea,
.field{
    // background: $color-white;
    color: $color-dark;
    font-size: 13px;
    font-family: $font-seravek;
    line-height: 40px;
    // height: 40px;
    border-radius: 0px;
    padding: 0 10px;
    border: 0;
    display: block;
    width: 100%;
    &.has-error{
        border: 1px solid red !important;
        &::-webkit-input-placeholder {
            color: red;
        }
        &:-ms-input-placeholder {
            color: red;
        }
        &::-moz-placeholder {
            color: red;
        }
        &:-moz-placeholder {
            color: red;
        }
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

textarea{
    line-height: 16px;
    height: auto;
    padding: 10px;
    min-height: 120px;
}

.form-project{
    padding: 25px 0;
    max-width: 950px;
    .row{
        .col{
            width: 50%;
            padding-right: 20px;
        }
    }
    .textarea-width{
        width: 100%;
    }
    .form-group{
        margin-bottom: 15px;
    }
    .prefix-phone{
        width: 80px;
        border: 0;
        box-shadow: none;
        height: 40px;
        .list{
            width:80px;
        }
    }
    .phone{
        width: calc(100% - 120px);
        /* float: right; */
        margin-left: 20px;
        display: inline-block;
        margin-bottom:0;
    }
    .box-options{
        list-style: none;
        padding: 0;
        li{
            width: 100px;
            cursor: pointer;
            margin-right: 15px;
            float: left;
            a{
                border-radius: 4px;
                background: $color-white;
                text-align: center;
                width: 100px;
                height: 100px;
                line-height: 100px;
                position: relative;
                display: block;
                &:after{
                    content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-check-white.svg);
                    position: absolute;
                    bottom: -40px;
                    right: 6px;
                    opacity: 0;
                }
                &:hover{
                    box-shadow: 0 5px 40px 0 rgba(80,80,80,.22);
                }
            }
                
            &.active{
                a{
                    box-shadow: 0 5px 40px 0 rgba(80,80,80,.22);
                    &:after{
                        opacity: 1;
                    }
                }
                
            }
            span{
                font-size: 13px;
                line-height: 18px;
                display: block;
                margin-top: 10px;
            }

        }
    }

    .field-note{
        font-size: 12px;
        font-style: italic;
        margin: 0;
        margin-top: 10px;
    }
    button.field{
        cursor: pointer;
        background: $color-white;
    }
    .upload-nav{
        position: relative;
        text-align: left;
        padding-left: 40px;
        &.url:before{
            content:url('https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-link.svg');
            position: absolute;
            top: 4px;
            left: 10px;
        }
        &.dropbox:before{
            content:url('https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-dropbox.svg');
            position: absolute;
            top: 4px; 
            left: 10px;
        }
    }
    .upload-ops{
        display: none;
        margin-top:10px;
        max-width: 680px;
        &.active{
            display: block;
        }
    }
}

.form-project{
    .fileactiondrag{
        // max-width: 550px
        .area-drag{
            &:before{
                top: 11px;
            }
        }
        .button-dropbox{
            button{
                &:before{
                    top: 6px;
                }
            }
        }
    }
    .append_files{
        p{
            color: $color-white;    
        }
    }   
}

.progress_circle{
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
}

.circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}
.notif_services_error{
    line-height: 1;
    margin-bottom: 20px;
    color: red !important;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {
    100%, 0% {
        stroke: $color-yellow;
    }
}