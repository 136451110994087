.section-detail-service{
  padding: 25px 0px;
  h2{
    font-weight: $font-medium;
  }
}
.section-process{
  margin: 0;
  .process-wrapper{
    &>.row-flex{
      display: block;
      .column:first-child{
        max-height: 320px;
      }
    }
    .process-list{
      padding: 0;
      margin-top: 0;
      ul{
        border: 0;
        li{
          .content{
            padding: 10px 12px 0;
            margin: 0;
          }
        }
      }
      .btn.with-arrow{
        min-width: 165px;
        &:before{
          display:none;
        }
      }
    }
    .section-description{
      max-width: 145px;
    }
  }
  .phone-showcase{
    transform: translateY(-205px) translateX(85px);
    max-width: 300px;
    @media (min-width: 375px){
      transform: translateY(-200px) translateX(90px);
    }
    @media (min-width: 425px){
      transform: translateY(-195px) translateX(80px);
    }
    @media (min-width: 500px) {
      transform: translateY(-190px) translateX(-100px);
      float: right;
    }
  }
}
.section-why{
  .why-list{
    display:flex;
    justify-content: space-around;
    flex-flow:row wrap;
    margin-top:40px;
    .why{
      flex: 1 0 25%;
      margin-right: 75px;
      margin-bottom: 20px;
      .why-logo{
        height:86px;
        width:86px;
        line-height:86px;
        text-align:center;
        background:#fff;
        border-radius:50%;
        box-shadow: 0px 7px 15px rgba(0,0,0,0.1);
      }
      .why-description{
        h4{
          font-size:15px;
          font-weight:500;
        }
      }
    }
  }
}

.section-features{
  overflow:hidden;
  .row-flex{
    margin-top:30px;
    overflow-y: auto;
    .column{
      flex:1 0 40%;
    }
  }
  .feature{
    display:flex;
    align-items:flex-start;
    .number{
      flex:0;
      padding:3px;
      margin-right:20px;
      background:#fff;
      border-radius: 50%;
      border:5px solid #eee;
      span{
        background: #fff;
        display: block;
        border-radius: 50%;
        text-align: center;
        height: 42px;
        width: 42px;
        line-height: 42px;
        font-size: 17px;
        border:2px solid $orange;
      }
    }
    .content{
      padding-right: 50px;
      h5{
        color: #212121;
        font-size: 16px;
        font-weight: 500;
        margin: 22px 0;
      }
    }
  }
}

.pricing-wrapper{
  color: #fff;
  .price-box{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 15px 0px;
    margin-right: 40px;
    margin-bottom:20px;
    color: #fff;
    .price-logo{
      position:absolute;
      top: 16px;
      left: -28px;
      padding: 6px;
      background:rgba(255, 255, 255, 0.2);
      border-radius:50%;
      span{
        display:block;
        height:42px;
        width:42px;
        line-height:48px;
        text-align:center;
        background:#fff;
        border-radius:50%;
      }
    }
    .price-content{
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      width: 150px;
      height: 150px;
      line-height: 150px;
      background: #15f13a;
      background: linear-gradient(90deg,#ecce36,#15f13a);
      position: relative;
      h2{
        margin-top:0;
        margin-bottom: 15px;
        font-size:32px;
        font-weight: 500;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #2E2F37;
      }
      p{
        margin-bottom:0;
      }
    }
  }
  .prices-desc{
    display: inline-block;
    vertical-align: middle;
    max-width: 768px;
  }
  .price-notes{
        width: 100%;
    h5{
      font-size: 15px;
      margin-bottom: 5px;
      margin-top: 30px;
    }
  }
}

.you-will-get-wrapper{
  margin-top:75px;
  ul{
    list-style: none;
    padding: 0;
    margin:0;
    li{
      background: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-check.svg) no-repeat 0 2px;
      padding: 0 0 5px 40px;
      list-style: none;
      margin: 0;
      color: #fff;
      font-size: 13px;
    }
  }
}

.question-wrapper{
  .head{
    h2{
      display: inline-block;
      margin-right: 10px;
    }
  }
  ul{
    list-style: none;
    padding:0;
    margin:0;
    li{
      display:flex;
      align-items: flex-start;
      margin-bottom: 30px;
    }
    .number{
      flex:0;
      padding:4px;
      margin-right:20px;
      background:rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      > span{
        display: block;
        background:#fff;
        border-radius: 50%;
        padding:3px;
        span{
          background: #fff;
          display: block;
          border-radius: 50%;
          text-align: center;
          height: 38px;
          width: 38px;
          line-height: 38px;
          font-size: 17px;
          border:2px solid $orange;
        }
      }
    }
    .content{
      color:#fff;
      .question{
        margin:14px 0;
        font-size:16px;
        font-weight:500;
      }
      .answer{
        padding-left:30px;
        display:flex;
        align-items: center;
        &:before{
          content:'';
          background: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-check.svg) no-repeat 0;
          height: 20px;
          width: 20px;
          margin-left: -30px;
          margin-right: 10px;
        }
      }
    }
  }
}

.frontend-solution{
  h2.yellow-text-gradient{
    text-align: center;
    margin-left: -10px;
    margin-right: -10px;
    font-size: 37px;
  }
  .menu-solution{
    display: none;
    ul{
      padding-top: 10px;
      padding-bottom: 10px;
      list-style: none;
      text-align: center;
      li{
        display: inline-block;
        text-align: center;
        margin: 0 8px;
        &.active{
          a{
            color: $color-yellow;
          }
        }
        &:last-child{
          &:after{
            content: '';
          }
        }
        &:after{
          content: '\f105';
          display: inline-block;
          font-family: 'fontawesome';
          font-size: 13px;
          vertical-align: middle;
          color: $color-yellow;
          position: relative;
          left: 11px;
          top: 0px;
        }
        a{
          color: #b1b1b1;
          text-decoration: none;
          font-size: 13px;
          font-weight: $font-regular;
        }
      }
    }
  }
}
.section-detail-service{
  .verticale-txt{
    color: $color-yellow;
    font-weight: $font-medium;
    font-size: 62px;
    display: inline-block;
    vertical-align: top;
  }
  .features-list{
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    // height: 60vh;
    height: auto;
    padding-bottom: 3em;
    .item-list{
      position: relative;
      margin-bottom: 10px;
      .icon{
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: top;
        border-radius: 50%;
        background: #58a2e9;
        margin: 0 10px 0 0;
        text-align: center;
        float: left;
        font-size: 13px;
        margin-top: 4px;
        color: #fff;
        padding: 5px;
        &.icon_search{
          &:before{
            content: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-faq-nda.svg);
            display: block;
            margin-top: 9px;
            margin-left: 6px;
            display: none;
          }
        }
      }
      .bg-white{
        border-radius: 4px;
        padding: 10px 0;
        display: inline-block;
        vertical-align: top;
        background: transparent;
        width: calc(100% - 60px);
        float: left;
      }
      h3{
        font-size: 15px;
        color: #fff;
        font-weight: $font-medium;
        line-height: 1;
        margin: 0;
      }
      p{
        font-size: 12px;
        color: #fff;
        font-weight: $font-regular;
        line-height: 1.5;
        margin-top: 12px;
        display: none;
      }
    }
  }
  .our-works{
    padding-bottom: 4em;
  }
  .question-list{
    margin-left: -5px;
    margin-right: -5px;
    ul{
      float: left;
      width: 100%;
      padding: 0 5px;
      &.quest1{
        width: 55%;
        li{
          &:first-child{
            height: 87px;
          }
          &:nth-last-child(3){
            height: 108px;
          }
          &:nth-last-child(2){
            height: 86px;
          }
          &:nth-last-child(1){
            height: 101px;
          }
          @media(max-width: 370px){
            &:first-child{
              height: 87px;
            }
            &:nth-last-child(3){
              height: 120px;
            }
            &:nth-last-child(2){
              height: 103px;
            }
            &:nth-last-child(1){
              height: 101px;
            }
          }
        }
      }
      &.quest2{
        width: 45%;
        li{
          &:first-child{
            height: 159px;
          }
          &:nth-last-child(2){
            height: 133px;
          }
          &:nth-last-child(1){
            height: 100px;
          }
          @media (max-width: 370px){
            &:first-child{
              height: 166px;
            }
            &:nth-last-child(2){
              height: 155px;
            }
          }
        }
      }
      &.quest3{
        li{
          width: 100%;
          margin-bottom: 10px;
          height: auto;
        }
      }
      &.quest4{
        width: 45%;
        li{
          height: 125px;
        }
      }
      &.quest5{
        width: 55%;
        li{
          height: 125px;
        }
      }
      li{
        display: inline-block;
        width: 100%;
        background: #fff;
        vertical-align: top;
        border-radius: 4px;
        padding: 12px;
        margin-bottom: 10px;
        .content{
          color: #212121;
          .question{
            margin-top: 0px;
            margin-bottom: 5px;
            font-size: 12px;
            line-height: 1.5;
            font-weight: $font-regular;
          }
          .answer{
            font-size: 12px;
          }
        }
      }
    }
  }
  .have-questions{
    padding-top: 10em;
    padding-bottom: 10em;
  }
}

.list-item-services{
  padding-top: 67px;
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      background-color: $color-white;
      border-bottom: 1px solid rgba(204, 204, 204, 0.45);
      &:last-child{
        border-bottom: none;
      }
      a{
        display: block;
        padding: 15px 12px;
        position: relative;
        &:after{
          content: '';
          width: 14px;
          height: 26px;
          background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/arrow.png);
          display: block;
          background-repeat: no-repeat;
          position: absolute;
          right: 25px;
          top: 50%;
          transform: translate(0%, -50%);
          background-size: 100%;
        }
        .icon{
          width: 50px;
          height: 50px;
          position: relative;
          display: inline-block;
          vertical-align: top;
          margin-right: 15px;
          img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-height: 30px;
          }
        }
        .desc{
          display: inline-block;
          vertical-align: top;
          h4{
            margin-top: 0;
            margin-bottom: 0;
            font-size: 14px;
            color: #212121;
            line-height: 50px;
            font-weight: $font-medium;
          }
          span{
            color: #8A8A8A;
            font-size: 13px;
            font-weight:$font-light;
          }
          .price{
            color: #212121;
            font-size: 14px;
            font-weight: $font-medium;
          }
        }
      }
    }
  }
}

.tab-slider{
    height: 370px;
    position: relative;
    background-position: center;
    margin-top: 0px;
    padding-top: 46px;
    overflow: hidden;
    margin-left: -25px;
    margin-right: -25px;
    .half-roundup{
      // height: calc(100vw - 55vw);
      overflow: hidden;
      top: -50px;
      position: relative;
      padding-top: 13px;
    }
    .scaler-roundup{
      transform: scale(.75);
      @media (min-width: 600px){
        transform: scale(1);
      }
    }
    .roundup{
      position: relative;
      width: 150%;
      transition: all 1s ease-in-out;
      top: -75px;
      margin-left: -25%;
      ul{
        position: absolute;
        list-style-type: none;
        padding: 0;
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        li{
          position: absolute;
          list-style-type: none;
          width: 0;
          height: 68%;
          margin: 16% 50%;
          // height: 40%;
          // margin: 15% 50%;
          -webkit-transform: rotate(-360deg);
          transition: all 0.8s linear;
          z-index: 400;
          // @media (max-width: 1024px) and (min-width: 769px){
          //   // height: 45%;
          //   height: 28%;
          //   margin: 12% 50%;
          // }
          a{
            position: absolute;
            left: 50%;
            bottom: 100%;
            margin-left: 0;
            text-align: center;
            font-size: 1px;
            overflow: hidden;
            cursor: pointer;
            color: #fff;
            opacity: 1;
            font-size: 18px;
            background: rgba(255, 255, 255, 0);
            border-radius: 100%;
            padding: 5px;
            width: 80px;
            height: 80px;
            line-height: 100%;
            margin-left: -35px;
            &.active{
              background: $color-white;
              span{
                display: block;
                width: 100%;
                height: 100%;
                border: 2px solid $color-yellow;
                border-radius: 50%;
                &:before{
                  content: '';
                  width: 15px;
                  height: 15px;
                  display: block;
                  background: $color-white;
                  border-radius: 50%;
                  border-radius: 50%;
                }
              }
            }
            img{
              display: block;
              max-width: 50px;
              min-height: 30px;
              height: auto;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
    .track{
      position: absolute;
      border-radius: 100%;
      border: 1px solid rgba(0, 0, 0, 0.12);
      z-index: -1;
      top: 35px;
      right: 0;
      left: 0;
      bottom: 0;
      margin: 0;
      width: 100vw;
      height: 100vw;
      // min-width: 358px;
      min-width: 320px;
      @media (min-width: 768px) { 
        top: 75px; 
        width: 107vw;
        height: 107vw;
        left: -5px;
        max-width: 780px;
        max-height: 760px;
      }
      @media (max-width: 425px) { top: 60px; }
      @media (max-width: 375px) { top: 36%; }
      @media (max-width: 320px) { top: 35px; }
    }
    .item-slider{
      position: absolute;
      top: 35%;
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 2;
      width: 30%;
      max-width: 960px;
      min-width: 320px;
      transition: all 0.5s ease-in;
      @media (min-width: 768px) { 
        top: 43%;
      }
      @media (max-width: 425px) { top: 40%; }

      *{
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        -webkit-animation-fill-mode: none;
        -moz-animation-fill-mode: none;
        -o-animation-fill-mode: none;
        animation-fill-mode: none;
      }
      &.active{
        *{
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
      }
      .item-name{
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 15px;
        font-weight: $font-medium;
        color: #2E2F37;
        line-height: 1.5;
        padding: 0 20px;
      }
      .item-desc{
        font-size: 12px;
        color: #2E2F37;
        font-weight: $font-regular;
        padding: 0 20px;
      }
      .item-image{
        img{
          margin: 0 auto;
          padding-top: 0;
          height: 80px;
          margin-top: -25px;
        }
      }
    }
  }
.btn-minWidth{
  min-width: 95px;
  .progress_circle{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}