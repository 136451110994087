.section-process {
  padding-bottom: 0;
  margin-bottom: -65px;
  z-index: 1;
  .section-heading{
    margin-top:0;
  }
  .phone-showcase {
    position:relative;
    margin-top: 35px;
    .base,.feature{
      background-size:contain;
      background-repeat:no-repeat;
      width: 300px;
      height: 300px;
      @media (min-width: 425px){
        width: 350px;
        height: 350px;
      }
      @media(min-width: 768px){
        width: 550px;
        height: 550px;
      }
    }
    .base{
      background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/howitworks/base.png);
    }
    .feature{
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      transition: transform .3s ease-in-out;
      &.shadow{
        filter: drop-shadow(-17px 12px 10px rgba(0,0,0,0.07));
      }
    }
    $translate :
      translate(2px,-2px)
      translate(4px,-4px)
      translate(6px,-6px)
      translate(8px,-8px)
      translate(10px,-10px)
      translate(12px,-12px)
      translate(14px,-14px);

    @for $i from 1 through 7 {
        $x : ($i*10px);
        $y : ($i*10px);
        .feature-#{$i} {
          background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/howitworks/#{$i}.png);
          transition-delay:(1500ms - ($i * 50));
          transform: nth($translate, $i);
        }
    }
  }
  .process-list {
    margin-top: 62px;
    padding: 0 80px;
    ul {
      padding: 0;
      list-style: none;
      margin: 0;
      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        position: relative;
        .number {
          flex: 0 0 62px;
          display: block;
          padding: 10px;
          border-radius: 50%;
          position:relative;
          transition: all .5s linear;
          .number-wrap {
            background: #fff;
            display: block;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            font-size: 12px;
            text-align: center;
            line-height: 25px;
            display:block;
            margin:0 auto;
            position: relative;
            transition: all .5s linear;
          }
          .bg-pulse{
            display: block;
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
            border-radius:50%;
            z-index:0;
          }
        }
        .content {
          padding: 10px 20px;
          color: #fff;
          h6 {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .description {
          transition: opacity 1s ease-out, transform 1s ease-out;
          opacity: 0;
          height: 0;
          transform: translateY(30px);
          overflow: hidden;
          p {
            font-size: 13px;
          }
        }
        &:last-child{
          margin-bottom:0;
        }
        &.active {
          &:not(:first-child){
            margin-top:25px;
          }
          .number {
            background: transparent;
            .number-wrap {
              height: 42px;
              width: 42px;
              line-height: 42px;
              font-size: 17px;
              .circle{
                visibility:visible;
                opacity: 1;
              }
            }

            .bg-pulse{
              background:rgba(255, 255, 255, 0.2);
              animation: pulses 1s ease-out infinite;
            }
          }
          .content {
            padding: 20px;
          }
          .description {
            opacity: 1;
            height: auto;
            margin-top: 10px;
            transform: translateY(0);
          }
        }
      }
    }
  }
  .process-description {
    font-size: 14px;
    padding: 20px 50px;
    color: #fff;
    a {
      color: inherit;
    }
  }
  .development-wrapper {
    text-align: center;
    .feature-list {
      list-style: none;
      padding: 0;
      margin: 0 0 20px;
      li {
        position: relative;
        display: inline-block;
        color: #ededed;
        font-size: 13px;
        margin-right: 20px;
        a {
          color: inherit;
          &:hover {
            color: #ff9500;
          }
        }
        &:not(:last-child):after {
          content: ">";
          position: absolute;
          top: -2px;
          right: -12px;
          color: #ff9500;
          font-size: 10px;
        }
        &.active {
          a {
            color: #ff9500;
            &:hover {
              color: #ededed;
            }
          }
        }
      }
    }
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
}
.radial-wrapper{
  position: relative;
  height:100vh;
  .radial-items{
    position:relative;
    display:block;
    width:100%;
    height:100vh;
    z-index:1;
    .radial-item{
      cursor:pointer;
      position: absolute;
      opacity: 0;
      .short{
        display:flex;
        align-items: flex-start;
        .logo{
          position:relative;
          flex:0;
          padding:15px;
          margin-right:20px;
          border-radius: 50%;
          .img-wrap{
            display: block;
            border-radius: 50%;
            text-align: center;
            height: 70px;
            width: 70px;
            line-height: 70px;
            transition: background .3s ease-in;
            z-index:1;
          }
          .bg-pulse{
            display: block;
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
            border-radius:50%;
            z-index:0;
          }
        }
        &:hover{
          .bg-pulse{
            background:rgba(255, 255, 255, 0.2);
            animation: pulses 1s infinite ease-out;
          }
          .logo{
            .img-wrap{
              background: #fff;
            }
          }
        }
      }
      .content{
        color:#fff;
        .title{
          font-size:15px;
          font-weight:500;
        }
        .price{
          p{
            padding-left:10px;
            margin:5px 0;
            font-weight: $font-x-light;
            font-size: 12px;
          }
          label{
            color:#fff;
            padding-left:40px;
          }
        }
      }
      &.active{
        .short{
          .logo{
            .bg-pulse{
              background:rgba(255, 255, 255, 0.2);
              animation: pulses 1s ease-out infinite;
            }
            .img-wrap{
              background: #fff;
            }
          }
        }
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6){
        .short{
          .logo{
            order:1;
            margin-right:0;
          }
          .content{
            text-align: right;
            margin-right:20px;
            .price{
              p{
                padding-left:0;
                padding-right:10px;
              }
              label{
                padding-left:0;
                padding-right:40px;
              }
            }
          }
        }
      }
    }
  }
  .radial-description{
    position: absolute;
    top: 50%;
    left: 50%;
    width:30%;
    transform: translate(-50%,-50%);
    text-align: center;
    color:#fff;
    z-index:2;
    h2{
      color: #fff;
      font-weight: 500;
      font-size: 62px;
      span{
        margin-top:10px;
        display:block;
      }
    }
    .btn{
      display:inline-block;
    }
  }
  .pulses{
    z-index:0;
    max-width:100%;
    .pulse{
      width:700px;
      height:700px;
      left:0;
      right:0;
      margin:0 auto;
    }
  }
}
.this-is-kodeku{
  text-align:center;
  .this-is-header{
    padding:75px 10px 0;
    h3{
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      margin-bottom: 0;
      .underlined{
        border-bottom: thin solid #ffa600;
      }
    }
    h2{
      margin-top:0;
      font-size:50px;
      font-weight:500;
      line-height:52px;
      margin-bottom: 10px;
      .yellow-text-gradient{
        font-size: 50px;
        font-weight: bold;
        text-transform: none;
      }
    }
    p{
      font-size:13px;
      margin-bottom: 12px;
      line-height: 20px;
    }
    .btn{
      margin-left:auto;
      margin-right:auto;
    }
  }
  .tab-slider{
    padding-top:0;
    .half-roundup{
      height:30vh;
      top:0;
      // @media (max-width: 1024px) and (min-width: 769px){
      //   height: 50vh;
      // }
    }
    .roundup ul li a.active span{
      border:none;
      &:before{
        display:none;
      }
      // &.img-wrap{
      //   display: block;
      //   border-radius: 50%;
      //   text-align: center;
      //   height: 70px;
      //   width: 70px;
      //   line-height: 70px;
      //   transition: background .3s ease-in;
      //   z-index:1;
      // }
      // &.bg-pulse{
      //   display: block;
      //   position:absolute;
      //   top:0;
      //   left:0;
      //   bottom:0;
      //   right:0;
      //   border-radius:50%;
      //   z-index:0;
      //   background:rgba(255, 255, 255, 0.2);
      //   animation: pulses 1s ease-out infinite;
      // }
    }
    .roundup{
      ul{
        li{
          a.active{
            overflow: visible;
            .bg-pulse{
              background:rgba(255, 255, 255, 0.2);
              animation: pulses 1s ease-out infinite;
              width: 120px;
              height: 120px;
              position: absolute;
              top: -20px;
              bottom: 0;
              left: -20px;
              right: 0;
            }
          }
        }
      }
    }
    .item-slider{
      padding:0 10%;
      top: 40%;
      .item-name{
        color:#fff;
        font-size:20px;
        margin:10px 0 0;
        line-height: 22px;
      }
      .item-starting{
        color:#fff;
        font-size:18px;
        margin:0 0 5px;
        font-weight: 500;
      }
      .item-desc{
        color:#fff;
        font-size:24px;
        margin:0 0 10px;
        font-weight: 500;
      }
    }
  }
}
@keyframes pulse {
  0% {
    transform: scale(.8);
  }
  100% {
    transform: scale(1);
  }
}
