.our-workspg{
	> h2.yellow-text-gradient{
		margin-bottom: 10px;
	}
	> p{
		font-size: 14px;
		color: $color-white;
		margin-bottom: 3em;
	}
	.our-works-list{
		// width: 100vw;
		margin-right: -10px;
		margin-left: -10px;
		.our-item{
			width: 50%;
			margin-bottom: 20px;
			margin-right: 0;
			padding: 0 10px;
			position: relative;
			@media (max-width: 320px){
				width: 100%;
			}
			.our-item{
				.titler__title{
					font-size: 20px;
				}
			}
			a{
				width: 100%;
				height: calc(100vw - 100%);
				.tilter__caption{
					padding: 11%;
				}
			}
		}
	}
	.set-goal{
		padding-bottom: 0;
		padding-top: 2em;
		h3{
			font-weight: $font-medium;
			margin-bottom: 12px;
			line-height: 1.5;
		}
		p{
			margin-top: 0;
		}
		.col{
			width: 100%;
		}
	}
}