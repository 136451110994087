.custom-dropdowns{
	//display: none;
	position: fixed;
	width: 100%;
	max-width: 833px;
	right: 0;
	background: #fff;
	height: auto;
	border-radius: 0;
	padding: 25px;
	box-shadow: 0px 0px 3px rgba(242, 242, 242, 0.44);
	z-index: 1000;
	top: 76px;
	height: calc(100vh - 76px);
	padding-top: 10em;
    padding-bottom: 0;
	padding-left: 55px;
    transform: translateX(40px);
    opacity: 0;
    visibility: hidden;
    transition: opacity .35s ease-in-out,transform .35s ease-in-out,visibility .35s ease-in-out,top .3s ease-out;
	&.opened{
		transform: translateY(0);
		opacity: 1;
		visibility: inherit;
	}
	.closed{
		position: absolute;
		background: $orange;
		width: 30px;
		height: 30px;
		left: -30px;
		top: 50%;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		text-align: center;
		line-height: 31px;
		a{
			color: $color-white;
			font-size: 12px;
			display: block;
			padding: 1px 0 0 2px;
			.fa:before{
				width: 10px;
    			height: 10px;
			}
		}
	}
	.item{
		display: inline-block;
		vertical-align: top;
		width: 30%;
		margin-bottom: 30px;
		border-bottom: 1px solid #eee;
		min-height: 85px;
		margin-right: 15px;
		a{
			&:hover, &:focus{
				text-decoration: none;
				.description{
					h3, span.prices{
						color: $orange;
					}
				}
			}
		}
		.images_thums{
			// border: 1.25px solid #ececec;
			width: 25px;
			height: 40px;
			background: #ffffff;
			// box-shadow: 0 5px 11px rgba(195, 195, 195, 0.19);
			display: inline-block;
			border-radius: 50%;
			position: relative;
			display: inline-block;
			vertical-align: top;
			img{
				position: absolute;
				top: 50%;
				left:50%;
				transform: translate(-50%, -50%);
			}
		}
		.description{
			display: inline-block;
			width: 80%;
			vertical-align: top;
			margin-left: 15px;
			.title{
				margin: 0;
				font-size: 13px;
				color: #212121;
				text-transform: none;
				font-weight: 500;
				line-height: normal;
				margin-bottom: 5px;
				font-family: 'Seravek';
			}
			p.desc{
				font-size: 13px;
				color: #212121;
				margin-bottom: 10px;
				font-family: 'Seravek';
				line-height: 1.5;
			}
			.starting-at{
				span{
					display: block;
					font-size: 13px;
					font-weight: 300;
					text-transform: none;
					font-family: 'Seravek';
					color: #b1b1b1;
				}
				span.prices{
					color: #212121;
					text-transform: none;
					font-weight: 600;
					margin-top: 5px;
					font-weight: 500;
				}
			}
		}
	}
	.sosmed-icon{
		position: absolute;
		right: 55px;
		bottom: 35px;
		a{
			display: inline-block;
			width: 30px;
			margin-top: 20px;
			.fa{
				color: $color-greyer;
				font-size: 18px;
			}
			&:hover{
				.fa{
					color:$color-black;
				}
			}
		}
	}
}
.dropdown{
  position: relative;
  display:inline-block;
  min-width: 250px;
  .dropdown-menu{
    position: absolute;
    top: 110%;
    left: -20%;
    right: 0;
    width: 120%;
    /* Styles */
    background: white;
    padding: 0;
    border-radius: inherit;
    box-shadow: 0 2px 35px 1px rgba(0,0,0,.1);
    font-weight: 400;
    transition: all 0.5s ease-in;
    list-style: none;
    text-align: left;
    border-radius:4px;
    z-index:9;

    /* Hiding */
    opacity: 0;
    pointer-events: none;
    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      right: 15px;
      border-width: 0 10px 10px 10px;
      border-style: solid;
      border-color: #fff transparent;
    }
    &:before{
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 100%;
      right: 13px;
      border-width: 0 12px 12px 12px;
      border-style: solid;
      border-color: rgba(0,0,0,0.01) transparent;
    }
    li {
      color: inherit;
      a {
        display: block;
        padding: 10px 15px 5px;
        text-decoration: none;
        color: #212121;
        font-size: 13px;
        font-weight:500;
        border-bottom: 1px solid rgba(0,0,0,0.05);
        transition: all 0.3s ease-out;
        cursor: pointer;
      }
      &:hover a{
        color: $orange;
      }
      &:first-of-type a {
        border-radius: 4px 4px 0 0;
      }
      &:last-of-type a {
        border: none;
        border-radius: 0 0 4px 4px;
      }
    }
  }
  &.dropdown-open{
    .dropdown-menu{
      opacity: 1;
      pointer-events: auto;
    }
  }
}
