// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
button{
	&.button{
		padding: 12px 20px;
		cursor: pointer;
		&:hover, &:focus{
			color: #fff;
			text-decoration: none;
		}
	}
}
.button{
	padding: 10px 12px;
	border-radius: 4px;
	background-color: #2E3038;
	color: #fff;
	font-size: 16px;
	font-family: 'Seravek';
	font-weight: 400;
	text-decoration: none;
  	display: inline-block;
	transition: all .5s ease;
	border:none;
	&:hover, &:focus{
		color: #fff;
		text-decoration: none;
	}
  &.button-rounded{
    padding: 10px 22px;
	  border-radius: 20px;
  }
  &.button-orange{
    color: #2e2f37;
    background:#ffa600;
  }
}

.btn{
	padding: 3px 13.35px;
	border-radius: 50px;
	background-color: #2E3038;
	color: #fff;
	font-size: 16px;
	font-family: 'Seravek';
	font-weight: 500;
	text-decoration: none;
	transition: all .5s ease;
	border: 0;
	height: 40px;
	line-height: 34px;
	cursor: pointer;
	&:hover, &:focus{
		color: #fff;
		text-decoration: none;
	}
	&.with-arrow{
		position: relative;
		border-radius: 50px;
		padding-left: 25px;
		&:after{
			content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-arrow.svg);
			line-height: 34px;
			float: right;
			margin-right: -35px;
			margin-left: 15px;
			margin-top: -0.01px;
    		transform: translateX(0);
			transition: transform .5s cubic-bezier(.19,1,.22,1);
		}
		&:before{
			content:'';
			display: block;
			width: 30px;
			height: 3px;
			background: #ffa600;    
			position: absolute;
			right: -15px;
			top: 19px;
			
		}
		&:hover{
			&:after{
				transform: translateX(15px);
			}
		}
	}
}
.btn-yellow{
	background: $color-yellow;
	color: $color-darker;
}
.btn-black{
	background: #2E3038;
	color: $color-white;	
}
a.with-arrow{
    max-width: 190px;
    margin-bottom: 20px;
}
.btn-block{
	  display: block;
}
