.detail{
	.scope-project{
		text-align: center;
		padding-top: 33px;
		padding-bottom: 35px;
		position: relative;
		.container{
			padding: 0 10px;
		}
		h3{
			margin: 0;
			margin-bottom: 10px;
			font-size: 13px;
			color: #212121;
			text-transform: uppercase;
			font-weight: $font-regular;
			line-height: 1;
		}
		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			li{
				display: inline-block;
				border: 1px solid rgba(0, 0, 0, 0.2);
				padding: 6px 12px;
				border-radius: 4px;
				margin-right: 5px;
    			margin-left: 5px;
				margin-bottom: 10px;
			}
		}
	}
	.backdrop-overlay{
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.54);
	}
	.project-detail{
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 100vh;
		position: relative;
		background-position: center 0;
		.detail-hero{
			height: calc(100vh - 170px);
			position: relative;
		}
		.web-development{
			color: $color-white;
			font-size: 14px;
			font-weight: $font-regular;
			margin-top: 25px;
			b{
				font-weight: $font-medium;
			}
		}
		.center-logo{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: calc(100% - 10%);
			img{
				width: 100%;
			}
		}
		.action-button-pages{
			position: absolute;
			bottom: 25px;
			width: 100%;
			a{
				padding: 8px 18px;
				font-size: 14px;
				&.visit-pages{
					float: left;
					background: $color-yellow;
					color: $color-white;
					&:before{
						content: "";
						background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/detail-view.svg);
						width: 15px;
						height: 15px;
						display: inline-block;
						vertical-align: middle;
						background-size: 100%;
						position: relative;
						left: -9px;
						top: -1px;
					}
				}
				&.next-project{
					float: right;
					background: transparent;
					color: $color-white;
					border: 1px solid rgba(255, 255, 255, 0.4);
					margin-left: 10px;
					&:before{
						content: "";
						background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/detail-next.svg);
						width: 15px;
						height: 15px;
						display: inline-block;
						vertical-align: middle;
						background-size: 100%;
						position: relative;
						left: 0;
						top: -1px;
					}
					&.prev{
						&:before{
							transform:rotate(180deg);
						}
					}
				}
			}
		}
		.img-bg{
			float: left;
		}
		.project-desc{
			float: left;
			max-width: 70%;
			padding-left: 5em;
			h4{
				font-size: 15px;
				font-family: $font-seravek;
				font-weight: $font-medium;
				margin: 0;
				line-height: 1;
				&.title{
					font-size: 16px;
				}
			}
			h3{
				font-size: 21px;
				color: #000;
				line-height: 1;
				margin-bottom: 2em;
			}
			.scope-works{
				margin-bottom: 5em;
				h4{
					margin-bottom: 10px;
				}
				ul{
					list-style: none;
					padding: 0;
					margin: 0;
					font-size: 13px;
					font-weight: $font-regular;
				}
			}
			.the-client{
				h4{
					margin-bottom: 10px;
				}
				p{
					font-size: 13px;
					font-weight: $font-regular;	
				}
				.bg-logos{
					width: 550px;
					height: 161px;
					border-radius: 4px;
					background: #fff;
					position: relative;
					img{
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
	.project-steps{
		padding: 4em 0;
		h4{
			font-size: 16px;
			font-family: $font-seravek;
			font-weight: $font-medium;
			margin: 0;
			line-height: 1;
			margin-bottom: 20px;
		}
		.project-goals{
			.items{
				margin-bottom: 25px;
				.number{
					width: 24px;
					height: 24px;
					border-radius: 50%;
					background: $orange;
					font-size: 13px;
					text-align: center;
					color: #fff;
					line-height: 24px;
					display: inline-block;
					vertical-align: top;
					margin-right: 15px;
				}
				.desc{
					max-width: 80%;
					display: inline-block;	
					vertical-align: top;
					h4.title{
						margin-left: 0;
						margin-right: 0;
						margin-bottom: 10px;
						font-size: 13px;
						&:after{
							content: '';
						}
					}
					p{
						font-size: 13px;
						font-family: $font-seravek;
						font-weight: $font-regular;
						color: $color-white;
					}
				}
			}
		}
		.steps-detail{
			max-width: 400px;
			h3{
				font-size: 18px;
				font-family: $font-seravek;
				font-weight: $font-medium;
				margin: 0;
				line-height: 1;
				margin-bottom: 10px;
			}
			.category{
				color: #1B1B26;
				font-size: 28px;
				font-weight: $font-x-light;
				margin-bottom: 5px;
			}
			p{
				font-size: 13px;
				color: $color-white;
				font-weight: $font-regular;
			}
		}
		.img-detail-step{
			img{
				width: 100%;
			}
		}
		.detail-works{
			.item-list{
				padding-right: 0;
				padding-left: 0;
				overflow: hidden;
				&.actived{
					padding-bottom: 0px;
				}
				h4.title{
					margin: 0 15.5px;
				}
				.content-hide{
					padding-top: 0;
					overflow: hidden;
					.img-detail-step{
						margin-top: 15.5px;
						margin-bottom: 0;
					}
					.cont-det-padd{
						padding: 15.5px;
					}
				}
			}
		}
	}
	.result{
		.items{
			margin-bottom: 15px;
			h4{
				margin-bottom: 10px;
			}
		}
	}
	.columns{
		display: flex;
		margin-bottom: 30px;
		.column{
			width: 65%;
			&:first-child{
				width: 45%;
			}
		}
	}
}