// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.contactus__float{
    position: fixed;
    bottom: 75px;
    right: 15px;
    z-index: 120;
    width: 50px;
    button{
        background: none;
        border:none;
        cursor: pointer;
        padding: 0;
        img{
            width: 100%;
        }
    }
}
.contact__us__content{
    // position: fixed;
    // width: 100%;
    // right: 0;
    // bottom: -100%;
    // z-index: 1000;
    // background: #fff;
    // border-radius: 4px;
    // transition-property: opacity, width, height, bottom;
    // transition-timing-function: ease-out;
    // transition-duration: 300ms;
    // height: 100%;
    // opacity: 0;
    background: #fff;
    padding-top: 80px;
    h2.yellow-text-gradient{
        font-size: 36px;
        padding-left: 25px;
        margin: 0 auto;
    }
    &.opended{
        opacity: 1;
        bottom: 0;
    }
    .head{
        background: #f8f8f8;
        border-bottom: 1px solid #ddd;
        color: #555;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        text-align: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .closed{
            width: 40px;
            height: 40px;
            background: #151515;
            color: #fff;
            font-size: 20px;
            line-height: 40px;
            text-align: center;
            display: block;
            border-radius: 100%;
            position: absolute;
            top: 5px;
            right: 5px;
            padding: 0;
            cursor: pointer;
            border:none;
            z-index: 11;
        }
        h3{
            font-size: 16px;
            line-height: 1;
            margin: 0;
            padding: 0;
            font-weight: $font-bold;
        }
    }
    .content{
        padding: 1.25rem 25px;
        // height: calc(100vh - 105px);
        max-height: 420px;
        overflow: auto;
        p{
            font-size: 12px;
            line-height: 1.5;
            font-style: italic;
            margin-top: 10px;
        }
        .items-result{
            display: none;
            padding-bottom: 15px;
            &.in{
                display: block;
            }
            ul{
                padding: 0;
                margin: 0;
                list-style: none;
                li{
                    background: #f8f8f8;
                    border: 1px solid #dadada;
                    border-radius: 4px;
                    padding: 10px 20px 10px 13px;
                    line-height: normal;
                    position: relative;
                    span{
                        font-size: 13px;
                        color: rgba(0, 0, 0, 0.37);
                    }
                    .remove_action{
                        position: absolute;
                        right: 12px;
                    }
                    &:before{
                        content: '';
                        display: inline-block;
                        vertical-align: middle;
                        background: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/upload_success.svg) no-repeat 0;
                        height: 20px;
                        width: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .item-field-container{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
            .fileuploadcontact{
                display: none;
            }
            .area_upload{
                text-align: center;
                background: #f8f8f8;
                border-radius: 4px;
                font-size: 14px;
                padding: 10.5px;
                border: 1px solid #ddd;
                cursor: pointer;
                position: relative;
                &.uploading{
                    .icon{
                        display: none;
                    }
                    .circular{
                        position: static;
                    }
                    .progress_circle{
                        display: inline-block !important;
                        position: static;
                    }
                }
                span{
                    display: inline-block;
                    margin-left: 5px;
                    position: relative;
                    z-index: 3;
                    font-size: 13px;
                }
                .icon{
                    margin-left: 0px;
                }
            }
            label{
                margin-bottom: 0;
                input, textarea{
                    border: 1px solid #e8e8e8;
                }
                textarea{
                    resize: none;
                }
            }
            &.number-info{
                .number-contact{
                    display: inline-block;
                    vertical-align: middle;
                    height: 40px;
                    margin-right: 10px;
                }
                .phones{
                    display: inline-block;
                    vertical-align: middle;
                    width: 100%;
                    border: 1px solid #e8e8e8;
                }
            }
        }
    }
    .actions_foot{
        padding: 1.25rem 25px;
        padding-top: 0;
        position: relative;
        &.progress{
            .progrss{
                display: block;
            }
        }
        &.success{
            .success{
                display: block;
            }
        }
        .progress_circle{
            display: none;
            left: auto;
            top: 10px;
            right: 20px;
            span{
                position: absolute;
                right: 30px;
                font-size: 15px;
                top: 0px;
                color: rgba(0, 0, 0, 0.38);
            }
            img{
                position: absolute;
            }
        }
        .btn.with-arrow{
            padding-left: 25px;
        }
    }
}

.footer-wrapper{
    position: relative;
    z-index:2;
    padding: 0 0 4em;
    .container{
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 25px;
        padding-right: 25px;
        min-width: auto;
    }
    .testi-wrapper{
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        padding: 50px 0;
        .testi-slider{
            position: relative;
            padding-bottom: 20px;
            .testi-item{
                text-align: center;
                outline: none;
                .testi-logo{
                    max-height: 30px;
                    margin: auto;
                    margin-bottom: 40px;
                }
                .testi-desc{
                    color: $color-yellow;
                    font-size: 24px;
                    font-weight: $font-x-light;
                    font-style: italic;
                    margin-bottom: 25px;
                    line-height: 28px;
                }
                .testi-owner{
                    color:$color-grey;
                    font-size: 13px;
                    font-weight: $font-light;
                }
            }
            .slick-arrow{
                position: absolute;
                bottom: 0;
                background: transparent;
                border: 0;
                cursor: pointer;
                &.prev{
                    left: 50%;
                    transform: translateX(-50%);
                    padding-right: 40px;
                }
                &.next{
                    right: 50%;
                    transform: translateX(50%);
                    padding-left: 40px;
                }
                .fa{
                    color: $color-yellow;
                }
            }
        }
    }
    .try-wrapper{
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        padding: 0 0 50px;
        position: relative;
        .try-box{
            border:thin solid #424242;
            padding: 50px 30px 50px 110px;
            margin-left: 77px;
            .stamp{
                position: absolute;
                left: 0;
                top: 35px;
            }
            .try-call{
                h5{
                    font-family: $font-seravek;
                    font-weight: $font-medium;
                    color: $color-yellow;
                    font-size: 18px;
                    margin-top: 0;
                }
                p{
                    font-weight: normal;
                    color: $color-grey;
                    font-size: 13px;
                    line-height: 18px;
                    margin-bottom: 35px;
                }
                span{
                    font-size: 14px;
                    color:$color-grey;
                    margin-left:15px;
                }

            }
        }
    }
    .footer-menu{
        width: 100%;
        .foot-top{
            padding: 30px 0 0;
            .row{
                max-width: $max-width;
                margin: auto;
            }
            .item-footer{
                padding: 4px 15px;
                margin-bottom: 20px;
                border-radius: 4px;
                color: #000;
                box-shadow: 0px 3px 10px rgba(167, 167, 167, 0.32);
                background-color: $color-white;
                &.actived{
                    h3{
                        &:after{
                            content: '-';
                        }
                    }
                }
                .content-hide{
                    display: none;
                    padding-bottom: 8px;
                    p {
                        color: $color-black;
                        margin-bottom: 5px;
                        i.fa{
                            color: $orange;
                            margin-right: 5px;
                        }
                        > a{
                            color: $color-black;
                            font-weight: $font-regular;
                        }   
                    }
                }
            }
        }
        .row{
            .col{
                width:25%;
                padding: 0 15px;
            }
        }
        .foot-contact{
            p{
                margin-bottom: 0;
                a.link-yellow{
                    color: $color-yellow;
                    font-weight: normal;
                }
                .fa{
                    margin-right: 5px;
                }
            }

        }
        h3{
            font-family: $font-seravek;
            font-size: 15px;
            font-weight: $font-medium;
            margin-bottom: 0;
            margin-top: 0;
            text-transform: uppercase;
            color: #151515;
            &:after{
                content: '+';
                font-weight: 500;
                float: right;
                font-size: 18px;
                color: rgba(0, 0, 0, 0.54);
            }
        }
        p{
            color:$color-black;
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 0;
            line-height: 20px;
            b{
                font-weight: 500;
                font-size: 12px;
            }
        }
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                a{
                    display: block;
                    color:$color-black;
                    font-size: 13px;
                    font-weight: 400;
                    &:hover{
                        color:$color-black;
                        text-decoration: none;
                    }
                }
            }
        }
        .subscribe-form{
            border-radius: 0px;
            height: 42px;
            padding: 0 0 0 10px;
            background: $color-white;
            border: thin solid #ddd;
            position: relative;
            &.invalid{
                background: #f0f0f0;
                border: thin solid #f0f0f0;
            }
            &.has-error{
                border: 1px solid red;
            }
            &.success{
                &:before{
                    visibility: visible;
                }
                .subscribe{
                    padding-left: 28px;
                }    
            }
            &.invalid{
                .subscribe{
                    padding-left: 28px;
                }
                &:before{
                    visibility: visible;
                    content: '';
                    position: absolute;
                    background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-close.svg);
                    background-color: #2e3038;
                    border-radius: 100%;
                    width: 20px;
                    height: 20px;
                    padding: 5px;
                    background-repeat: no-repeat;
                    background-size: 10px;
                    background-position: center;
                    top: 11px;
                    left: 10px;
                }
            }
            &.registered{
                .subscribe{
                    padding-left: 28px;
                }
                &:before{
                    visibility: visible;
                    content: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-smile.svg);
                }
            }
            .progress_circle{
                display: none;
                top: 11px;
                left: 9px;
            }
            &.progress{
                .subscribe{
                    padding-left: 28px;
                }
                .progress_circle{
                    display: block;
                }
            }
            .subscribe{
                border:0;
                background: transparent;
                color:$color-grey;
                width: calc(100% - 75px);
                line-height: 40px;
                font-size: 13px;
                font-family: $font-seravek;
                display: inline-block;
                padding-left: 0;
                &::-webkit-input-placeholder{
                    color: $color-grey;
                }
            }
            .btn-subscribe{
                border:0;
                width: 57px;
                background: transparent;
                color: #747474;
                font-size: 13px;
                font-weight: $font-medium;
                text-transform: uppercase;
                line-height: 40px;
                font-family: $font-seravek;
                cursor: pointer;
                display: inline-block;
            }
            &:before{
                content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-check.svg);
                position: absolute;
                top: 12px;
                left: 10px;
                visibility: hidden;
            }
        }
        .sosmed-icon{
            a{
                display: inline-block;
                width: 35px;
                margin-top: 10px;
                margin-bottom: 10px;
                .fa{
                    color: $color-black;
                    font-size: 20px;
                }
                &:hover{
                    .fa{
                        color:$color-grey;
                    }
                }
            }
        }
        .foot-bottom{
            max-width: $max-width;
            margin: auto;
            margin-top: 15px;
            h3{
                color:$color-black;
            }
            ul{
                li{
                    a{
                        color: $color-greyer;
                        &:hover{
                            color: $color-grey;
                        }
                    }
                }
            }
        }
    }
    .copyright{
        width: 100%;
        margin: auto;
        color:$color-black;
        font-size: 13.0px;
        font-weight: 400;
        margin-top: 20px;
        padding: 15px 0;
        p{
            margin-bottom: 0;
            font-size: 12px;
        }
        a.link-yellow{
            color: $color-yellow;
            font-weight: normal;
        }
        &:before{
            content: '';
            display: inline;
            width: 15px;
        }
    }
}
