.white-title{
	font-size: 18px;
	font-family: $font-seravek;
	font-weight: $font-medium;
	text-transform: uppercase;
	color: $color-white;
	margin-top: 15px;
	margin-bottom: 1em;
	text-align: center;
	line-height: 1.5;
}
.content-tabs{
	.question{
		text-align: center;
		color: #fff;
		a{
			color: #fff;
			font-weight: $font-regular;
			&.hover, &.focus{
				color: #fff;
			}	
		}
	}
}
.ourpolicies{
	.yellow-text-gradient, .yellow-text-gradient .grey{
	    font-size: 32px;
	    text-align: center;
	}
	&.faq{
		.yellow-text-gradient{
			margin-top: 15px;
			margin-bottom: 30px;
		}
		.content-tabs{
			 .items-content{
			 	 width: 100%;
			 	 .list{
			 	 	 .desc{
			 	 	 	max-width: 80%;
			 	 	 }
			 	 }
			 }
		}
		.columns{
			display: flex;
			margin-bottom: 30px;
			.column{
				width: 50%;
			}
		}
	}
	.menu-side{
		width: 100%;
		margin-bottom: 25px;
		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			li{
				display: inline-block;
				text-align: center;
				margin: 0 15px;
				&.active, &.slick-current{
					a{
						color: $color-yellow-light;
						border-bottom: 1px solid $color-yellow-light;
					}
				}
				a{
					font-weight: $font-regular;
					color: rgba(255, 255, 255, 0.72);
					font-size: 14px;
					display: inline-block;
					text-decoration: none;
					height: 25px;
				}
			}
		}
	}
	.content-tabs{
		width: 100%;
		.items-content{
			display: none;
			&.open{
				display: block;
			}
			&.question-listing{
				.item-list{
					padding: 15.5px;
					&.actived{
						h4.title{
							&:after{
								content: "-";
							}
						}	
					}
					h4.title{
						padding-bottom: 0;
						position: relative;
						span{
							max-width: 220px;
							display: block;
						}
						&:after{
							content: "+";
							font-weight: 500;
							color: #F5A523;
							position: absolute;
							top: 50%;
							right: 0;
							font-size: 18px;
							transform: translate(0%, -50%);
						}
					}
					.content-hide{
						display: none;
						padding-top: 15px;
						p{
							margin-bottom: 0;
							color: #8C8C8C;
						}
					}
				}
			}
			.list{
				.number{
					width: 50px;
					height: 50px;
					background: #fff;
					border-radius: 50%;
					border: 4px solid #efefef;
					line-height: 35px;
					text-align: center;
					font-size: 15px;
					position: relative;
					color: #747474;
					font-weight: 400;
					padding: 2px;
					display: inline-block;
					vertical-align: top;
					span{
						// position: absolute;
						display: block;
						width: 100%;
						height: 100%;
						border: 2px solid #FFA600;
						left: 0;
						top: 0;
						border-radius: 50%;
					}
				}
				.desc{
					display: inline-block;
					max-width: 90%;
					vertical-align: top;
					margin-left: 15px;
					padding-top: 8px;
					h4{
						color: #2E2F37;
						font-weight: 500;
						font-size: 16px;
						margin: 0;
						line-height: normal;
						margin-bottom: 12px;
					}
					p{
						font-size: 13px;
						color: #2E2F37;
					}
				}
			}
			.item-list{
				&.full{
					max-width: 100%;
					min-height:180px;
				}
				&.no-bg{
					background: transparent;
					text-align: center;
					p{
						color: rgba(255, 255, 255, 0.72);
						margin-top: 15px;
						a, a:hover, a:focus{
							color: rgba(255, 255, 255, 0.72);	
							text-decoration: underline;
							font-weight: $font-regular;
						}
					}
				}
				.icon{
					width: 30px;
					height: 30px;
					border-radius: 50%;
					// background: #2e2e2e;
					position: absolute;
					bottom: 15px;
				}
			}
		}
	}
	.icon{
		text-align: center;
		&.ic_contract{
			background: #00bf2b;
			&:before{
				content: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-faq-contract.svg);
				display: block;
				margin-top: 8px;
			}
		}
	}
}