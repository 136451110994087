// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------





/// Regular font family
/// @type List
$text-font-stack: 'Seravek', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

/// Kodeku font family
/// @type List
$font-seravek: 'Seravek', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-graphik: 'Graphik', sans-serif;




/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// @type Color
$color-black: #000000;
/// @type Color
$color-yellow: #FFA600;
/// @type Color
$color-white: #ffffff;
/// @type Color
$color-grey: #bbbbbb;
/// @type Color
$color-greyer: #999999;
/// @type Color
$color-darker: #151515;
/// @type Color
$color-dark: #303030;
/// @type Color
$color-light-grey: #eaeaea;

/// @type Color
$color-yellow-light: #ffeb00;


// color background
$color-background: #f6f6f6;

// color field border
$color-border: #e4e4e4;

$color-blue-bg: #1aa6cf;


$orange: #ffa600 !default;
/// @type List
$font-x-light : 200;
$font-light : 300;
$font-regular : normal;
$font-medium : 500;
$font-semibold : 600;
$font-bold : bold;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;






/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
