.section-sitemap{
  min-height:650px;
  .section-heading{
    margin-bottom:30px;
  }
  ul{
    list-style: none;
    padding:0;
    margin:0;
    &.siteall{
      ul{
        margin-bottom: 25px;
        >li{
          box-shadow: none;
        }
      }
      > li{
        > a{
          margin-bottom: 15px;
          display: block;
        }
      }
    }
    > li {
      margin-bottom:16px;
      background: #fff;
      padding: 12px 18px;
      margin-right: 0;
      border-radius: 4px;
      box-shadow: 0px 4px 7px rgba(202, 202, 202, 0.28);
      // max-width: 237px;
      @media(max-width: 370px){
        max-width: 100%;
        margin-bottom: 22px;
      }
      > a{
        color: #2e2f37;
        font-size: 15px;
        font-weight: 500;
        &:hover{
          // text-decoration: underline;
        }
      }
      > ul{
        li{
          margin-bottom:0;
          padding: 0;
          a{
            font-size:14px;
            font-weight: 400;
          }
        }
      }
    }
    .icon{
      width: 35px;
      height: 35px;
      border-radius: 50%;
      // background: #c73299;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      text-align: center;
      // padding: 6px;
      &:before{
        content: '';
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        display: block;
      }
      &.icon_sitemaps{
        &:before{
          content: '';
          width: 100%;
          height: 100%;
          background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-faq-dialogue.svg);
          background-repeat: no-repeat;
          background-size: 100%;
          display: block;
        }
      }
      &.icon_siteHome{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/home.svg);
        }
      }
      &.icon_siteOurworks{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/ourworks.svg);
        }
      }
      &.icon_siteAgency{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/foragencies.svg);
        } 
      }
      &.icon_siteQuotes{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/getafreequotes.svg);
        } 
      }
      &.icon_siteHowWorks{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/howitworks.svg);
        }  
      }
      &.icon_siteDevstory{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/developmentstory.svg);
        }  
      }
      &.icon_sitePricing{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/pricing-service.svg);
        }  
      }
      &.icon_siteFaq{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/faq.svg);
        }  
      }
      &.icon_sitePolicies{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/ourpolicy.svg);
        }  
      }
      &.icon_sitemContact{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/contact.svg);
        }  
      }
      &.icon_siteServices{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/services.svg);
        } 
      }
      &.icon_siteOrder{
        &:before{
           background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/sitemaps/ordernow.svg);
        } 
      }
    }
  }
  .column{
    &.left1{
      left: 30px;
      position: relative;
      @media(max-width: 370px){
        left: 0;
      }
    }
    &.left2{
      left: 60px;
      position: relative;
      @media(max-width: 370px){
        left: 0;
      }
    }
    &.left3{
      left: 90px;
      position: relative;
      @media(max-width: 370px){
        left: 0;
      }
    }
  }
}
