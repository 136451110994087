.contactus{
	// padding-top: 50px;
	.contactus-located{
		display: inline-block;
		vertical-align: top;
		h4, h5{
			margin: 0;
			font-size: 14px;
			line-height: normal;
			color: $color-white;
		}
		h4{
			font-weight: 500;
			margin-bottom: 5px;
		}
		h5{
			font-weight: 400;	
			font-style: italic;
		}
	}
	.free-quotes{
		display: inline-block;
		vertical-align: top;
		margin-top: 25px;
		.button-quotes{
			border-radius: 4px;
			max-width: 245px;
			// font-weight: 400;
			// padding: 10px 20px;
		}
	}
}
.contactus-detail{
	margin-top: 20px;
}