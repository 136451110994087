.agency{
	.hero{
		background: #F2F2F2;
		.min-section{
			min-height: 85vh;
		}
		.img-hero{
			position: relative;
			height: 50vh;
			bottom: -7em;
			left: -7em;
			transition: all .8s ease;
			@media (min-width: 768px){
				height: 65vh;
			}
			&.inslide{
				bottom: 0;
				left: 0;
			}
			img{
				position: absolute;
				bottom: -3em;
				left: -10em;
				margin-left: 0;
				max-width: calc(100vw + 80vw);
				@media (min-width: 375px){
					bottom: -6em;
					left: -13em;
				}
				@media (min-width: 768px){
					max-width: 1200px;
					bottom: -8em;
					left: -20em;
				}
				@media (max-width: 400px) and (min-width: 330px){
					left: -11em;
				}
				&.a{
					z-index: 5;
				}
				&.b{
					z-index: 2;
				}
			}
		}
		.hero-title{
			.construct-desc{
				text-align: left;
				margin-left: 8.5em;
			}
			h2{
				font-size: 36px;
				margin-bottom: 5px;
				@media (max-width: 320px) {
					font-size: 35px;
				}
				&:last-of-type{
					margin-bottom: 10px;
				}	
			}
			p{
				font-size: 13px;
				font-weight: $font-light;
				line-height: 1.5;
			}
		}
	}
	.help-productifity{
		padding-top: 2em;
	}
	.head{
		h2.normal, h2.yellow-text-gradient{
			margin: 0;
			margin-bottom: 5px;
			line-height: 1;
		}
		p{
			line-height: 20px;
			font-size: 13px;
			margin-top: 10px;
		}
	}
	.help-productifity{
		.list-productifity{
			display: block;
			padding-top: 25px;
			.item{
				width: 267px;
				// background: #f1f1f1;
				margin-right: 20px;
				position: relative;
				@media (max-width: 370px) {
					width: 100%;
					left: 0 !important;
				}
				&:last-child{
					margin-right: 0;
				}
				.box__inside{
					width: 100%;
					height: 110px;
					border-radius: 4px;
					padding: 15px;
					background: #fff;
					box-shadow: 0 7px 13px rgba(0, 6%, 29%, 0.19);
					margin-bottom: 15px;
					@media (max-width: 370px) {
						margin-bottom: 25px;
					}
					@media(min-width: 768px){
						margin-bottom: 25px;
					}
					span{
						display: block;
						border: 1px solid rgba(255, 255, 255, 0.36);
						width: 100%;
						height: 100%;
						color: #fff;
						padding: 5px;
						position: relative;
						.img__box{
							height: 100%;
							line-height: normal;
							text-align: center;
							width: 70px;
							display: inline-block;
							vertical-align: middle;
							margin-right: 15px;
							position: relative;
							img{
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
							}
						}
					}
				}
				.text__box{
					font-size: 15px;
					font-family: $font-seravek;
					font-weight: $font-regular;
					display: inline-block;
					vertical-align: middle;
					max-width: 125px;
				}
				&.pd1{
					// padding-top: 15em;
					left: 0vw;
					.box__inside{
						background: #c83399;
					}
				}
				&.pd2{
					// padding-top: 12em;
					left: 3vw;
					@media(min-width: 768px){
						left: 8vw;
					}
					.box__inside{
						background: #4ebb46;
					}
				}
				&.pd3{
					// padding-top: 8em;
					left: 6vw;
					@media(min-width: 768px){
						left: 16vw;
					}
					.box__inside{
						background: #f39033;
					}
				}
				&.pd4{
					// padding-top: 4em;
					left: 9vw;
					@media(min-width: 768px){
						left: 24vw	;
					}
					.box__inside{
						background: #af71d2;
					}
				}
				&.pd5{
					left: 12vw;
					@media(min-width: 768px){
						left: 32vw;
					}
					.box__inside{
						background: #6f2ea6;
					}
				}
			}
		}
	}
	.our-competency{
		.agency-competency{
			margin-top: 20px;
		}
		.img_thums{
			width: 60px;
			height: 60px;
			border: 9px solid rgba(255,255,255,.2);
    		background: transparent;
			border-radius: 50%;
			position: relative;
			display: inline-block;
			vertical-align: top;
			&:before{
				content: '';
				width: 42px;
				height: 42px;
				background: $color-white;
				border-radius: 50%;
				display: block;
			}
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				max-width: 25px;
				display: block;
				transform: translate(-50%, -50%);
			}
		}
		.competen-desc{
			display: inline-block;
			vertical-align: top;
			padding-top: 10px;
			margin-left: 15px;
			h3{
				margin: 0;
				font-size: 21px;
				color: $color-white;
				font-weight: $font-medium;
				text-transform: uppercase;
				padding-bottom: 15px;
			}
			ul{
				padding: 0;
				margin: 0;
				list-style: none;
				display: inline-block;
				vertical-align: top;
				font-size: 13px;
				font-weight: $font-regular;
				margin-right: 30px;
				max-width: 150px;
				min-width: 150px;
				color: $color-white;
				li{
					margin-bottom: 0px;
				}
			}
		}
		.all-program{
			padding-top: 20px;
			ul{
				padding: 0;
				margin: 0;
				list-style: none;
				display: inline-block;
				vertical-align: top;
				li{
					display: inline-block;
					margin-right: 27px;
					margin-bottom: 20px;
					img{
						filter: invert(100%);
					}
				}
			}
		}
		.started{
			h2{
				margin: 0;
				line-height: 1;
			}
			p{
				font-size: 15px;
				font-weight: $font-light;
				color: $color-white;
				margin-left: 7px;
				margin-top: 5px;
			}
		}
	}
	.get-qotes{
		padding-bottom: 4em;
		.get-qotes-now{
			// padding-bottom: 4em;
			padding-top: 1em;
			float: none;
			width: 100%;
		}
		h2{
			margin: 0;
			line-height: 1;
			text-align: left;
		}
		// .columns{
		// 	display: flex;
		// 	margin-bottom: 30px;
		// 	.column{
		// 		width: 50%;
		// 	}
		// }
		.save{
			// width: 307px;
			// height: 307px;
			// background: transparent;
			// border-radius: 50%;
			position: relative;
			font-size: 48px;
			font-weight: $font-medium;
			color: #2B2F36;
			// border: 1px solid #efefef;
			span{
				color: $color-white;
			}
			h3{
				margin: 0;
				line-height: 1;
				display: block;
				font-size: 18px;
				font-weight: $font-medium;
				color: $color-white;
				text-transform: uppercase;
			}
			.sv_position{
				// position: absolute;
				// top: 50%;
				// left: 50%;
				color: $color-white;
				// transform: translate(-50%, -50%);
			}
		}
	}
	.descQuote{
		font-size: 32px;
		font-weight: $font-medium;
		color: $color-white;
		span{
			color: $color-white;
		}
		h3{
			margin: 0;
			line-height: 1;
			display: block;
			font-size: 18px;
			font-weight: $font-medium;
			color: $color-white;
			text-transform: uppercase;
		}
		p{
			font-size: 13px;
			color: $color-white;
			font-weight: $font-regular;
		}
		ul{
			margin: 0;
			padding: 0;
			list-style: none;
			li{
				margin-bottom: 0;
			}
		}
	}
	.rates{
		padding-top: 1em;
		display: flex;
		p{
			font-size: 13px;
			color: $color-white;
			font-weight: $font-regular;
			padding-right: 10px;
			line-height: 1.5;
		}
		.column{
			width: 100%;
		}
	}
	.rates-notes{
		p{
			font-size: 13px;
			color: $color-white;
			font-weight: $font-regular;
			line-height: 1.5;
		}
	}
	.section-gradient{
		background: #5c76b7;
		background: linear-gradient(195deg,#5c76b7,#39d4a9);
		&.gd-green{
			background: #8982e8;
		    background: linear-gradient(90deg,#08e879,#8982e8)
		}
	}
	.have-questions{
		&.form-question{
			padding: 10em 0em;
			.yellow-text-gradient{
				-webkit-background-clip: initial;
				-webkit-text-fill-color: initial;
				background: none;
				color: $color-yellow-light;
			}
		}
		.question{
			> .column{
				width: 55%;
				&:first-child{
					width: 35%;
				}
			}
		}
		.columns{
			display: flex;
			margin-bottom: 30px;
			.column{
				width: 50%;
			}
		}
		padding: 4em 0;
		h2{
			margin: 0;
		}
		.head{
			margin-bottom: 3em;
			h2{
				display: inline-block;
				margin-right: 5px;
			}
			p{
				color: #fff;
				margin-top: 5px;
				font-size: 15px;
				font-weight: $font-regular;
				margin-left: 5px;
			}
		}
		
		.list{
			// min-height: 130px;
			.number{
				width: 50px;
				height: 50px;
				background: #fff;
				border-radius: 50%;
				border: 4px solid #efefef;
				line-height: 35px;
				text-align: center;
				font-size: 15px;
				position: relative;
				color: #747474;
				font-weight: 400;
				padding: 2px;
				display: inline-block;
				vertical-align: top;
				span{
					// position: absolute;
					display: block;
					width: 100%;
					height: 100%;
					border: 2px solid #FFA600;
					left: 0;
					top: 0;
					border-radius: 50%;
				}
			}
			.desc{
				display: inline-block;
				max-width: 80%;
				vertical-align: top;
				margin-left: 15px;
				padding-top: 8px;
				h4{
					color: #FFF;
					font-weight: 500;
					font-size: 16px;
					margin: 0;
					line-height: normal;
					margin-bottom: 12px;
				}
				p{
					font-size: 13px;
					color: #FFF;
					margin-bottom: 0;
				}
			}
		}
	}
	.match-form{
		.desc, .italic{
			color: #fff;
			// padding: 0 15px;
		}
		.columns{
			margin-bottom: 15px;
		}
		.field{
			width: 100%;
			padding-right: 20px;
			padding-left: 0;
		}
		.italic{
			font-style: italic;
		}
		.row{
			margin: 0;
		}
		.form-project{
			.row{
				.field{
					padding-left: 40px;
					background: #fff;
				}
				.col:last-child{
					padding-right: 0;
				}
			}
		}
	}
	h2{
		&.normal, &.yellow-text-gradient{
			font-size: 24px;
		}
	}
}

.question-listing{
	.item-list{
		background: $color-white;
		border-radius: 4px;
		padding: 20px;
		padding-bottom: 35px;
		max-width: 250px;
		min-height: 260px;
		display: inline-block;
		vertical-align: top;
		margin-right: 25px;
		margin-bottom: 20px !important;
		position: relative;
		box-shadow: 0px 4px 7px rgba(202, 202, 202, 0.28);
		h4.title{
			margin: 0;
			font-size: 14px;
			color: $color-dark;
			font-weight: $font-medium;
			line-height: normal;
			padding-bottom: 10px;
			margin-bottom: 10px;
		}
		p{
			line-height: 1.6;
			font-size: 12px;
			color: #b1b1b1;
		}
		.icon{
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background: #2e2e2e;
			position: absolute;
			bottom: 15px;
			text-align: center;
			&.ic_dialogue{
				background: #c83399;
				&:before{
					content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-faq-dialogue.svg);
					display: block;
					margin-top: 4px;
				}
			}
			&.ic_contract{
				background: #00bf2b;
				&:before{
					content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-faq-contract.svg);
					display: block;
					margin-top: 8px;
				}
			}
			&.ic_nda{
				background: #ff8800;
				&:before{
					content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-faq-nda.svg);
					display: block;
					margin-top: 6px;
					margin-left: 5px;
				}
			}
			&.ic_dedicated{
				background: #ba6ad8;
				&:before{
					content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-faq-dedicated.svg);
					display: block;
					margin-top: 4px;
				}
			}
			&.ic_payment{
				background: #7922ac;
				&:before{
					content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-faq-payment.svg);
					display: block;
					margin-top: 6px;
					margin-left: 2px;
				}
			}
			&.ic_communicate{
				background: #58a2ea;
				&:before{
					content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-faq-nda.svg);
					display: block;
					margin-top: 6px;
					margin-left: 5px;
				}
			}
			&.ic_leaf{
				background: #6b65f1;
				&:before{
					content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-faq-leaf.svg);
					display: block;
					margin-top: 6px;
				}
			}
		}
		&.full{
			max-width: 100%;
			min-height:180px;
		}
		&.no-bg{
			background: transparent;
			text-align: center;
			box-shadow: none;
			p{
				color: #2e2e2e;
				margin-top: 15px;
				a, a:hover, a:focus{
					color: #2e2e2e;	
					text-decoration: underline;
					font-weight: $font-regular;
				}
			}
		}
	}
}
.area-drag{
	width: 70%;
	background: #fff;
	height: 40px;
	padding: 9px;
	border-radius: 4px;
	padding-left: 40px;
	position: relative;
	float: left;
	cursor: pointer;
	p{
		margin-bottom: 0;
	}
	&:before{
		content: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-link.svg);
		position: absolute;
		top: 5px;
		left: 10px;
	}
}
.button-dropbox{
	float: right;
	width: 28%;
	button{
		float: right;
		letter-spacing: 1px;
		background: #fff;
		color: #000;
		position: relative;
		padding-left: 40px;
		font-weight: $font-medium;
		text-transform: uppercase;
		font-size: 14px;
		&:before{
			content: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-dropbox.svg);
			position: absolute;
			top: 6px;
			left: 10px;
		}
		&:hover, &:focus{
			color: #000;
		}
	}
}
#fileupload{
	display: none;
}

.append_files{
	margin-bottom: 25px;
	p{
		color: #fff;
		margin-bottom: 5px;
		position: relative;
		&:before{
			content: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-check.svg);
			display: inline-block;
			vertical-align: middle;
			position: relative;
			top: 2.5px;
			margin-right: 10px;
		}
	}
}
.items-content{
	width: 100%;
	.item-list{
		background: #fff;
		border-radius: 4px;
		padding: 15.5px;
		width: 100%;
		max-width: 100%;
		min-height: auto;
		display: inline-block;
		vertical-align: top;
		margin-right: 0;
		margin-bottom: 20px;
		position: relative;
		h4.title{
			margin: 0;
			font-size: 15px;
			color: #4a4a4a;
			font-weight: 500;
			line-height: normal;
			padding-bottom: 0;
			position: relative;
			span{
				max-width: 220px;
				display: block;
			}
			&:after{
				content: "+";
				font-weight: 500;
				color: #f5a523;
				position: absolute;
				top: 50%;
				right: 0;
				font-size: 18px;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}
		.content-hide{
			display: none;
			padding-top: 15px;
			p{
				margin-bottom: 15px;
				color: #8c8c8c;
				line-height: 20px;
				font-size: 13px;
				&:last-child{
					margin-bottom: 0;
				}
				strong{
					display:block;
				}
				a{
					color: $color-yellow;
					font-weight:normal
				}
			}
			ul{
				list-style: none;
				margin: 0;
				padding: 0;
				li{
					font-size: 13px;
					color: rgba(0,0,0,.54);
				}
			}
		}
		&.actived{
			h4.title{
				&:after{
					content: "-";
				}
			}
		}
	}
}