.pagination-container{
	position: relative;
	z-index:7;
	&.fadein{
		.pagination.one-page{
			opacity: 1;
		}
	}
}
.pagination.one-page{
	list-style: none;
	position: fixed;
    z-index: 8;
	top: 0;
	left: inherit;
	margin: 0;
	padding: 0;
	height: 100%;
	display: block !important;
	transition: all .5s ease-in-out;
	opacity: 0;
	ul{
		padding: 0;
		position: absolute;
		top: 53%;
		transform: translate(0%, -50%);
	}
	li{
		display: block;
		&.active{
			a{
				background: $orange;
			}
		}
		&.next, &.prev{
			a{
				text-align: center;
				color: $color-black;
				border-radius: 0;
				background: transparent;
				width: auto;
				height: auto;
			}
		}
		&.next{
			margin-bottom: 9em;
			margin-top: 7em;
			.fa-long-arrow-up{
				&:before{
					content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-arrow-up.svg);
					display: block;
					width: 10px;
					height: 20px;
				}
			}
			
		}
		&.prev{
			margin-top: 11em;
			position: absolute;
			bottom: 2em;
			.fa-long-arrow-down{
				&:before{
					content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-arrow-down.svg);
					display: block;
					width: 10px;
					height: 20px;
				}
			}
		}
		a{
			text-decoration: none;
			margin: 13px 10px;
			display: block;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: rgba(0, 0, 0, 0.12);
		}
	}
}