// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

.section-gradient{
  background: rgba(123,118,198,1); /* fallback for old browsers */
  background: -webkit-linear-gradient(30deg,#1bb683,#3c9ca3,#5987b7,#7b76c6,#5987b7,#3c9ca3,#7b76c6); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(30deg,#1bb683,#3c9ca3,#5987b7,#7b76c6,#5987b7,#3c9ca3,#7b76c6);
  .container{
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
    min-width: auto;
  }
  .section-description{
    color:#ededed;
  }
  h1, h2, h3, h4, h5, h6, p, label{
    color: $color-white;
  }
  p{
        font-size: 13px;
    line-height: 20px;
  }
  .field, input[type=email], input[type=number], input[type=password], input[type=text], select, textarea{
    border: thin solid $color-white;
    border-radius: 0;
    background: transparent;
    color: $color-white;
    height: 40px;
    &::placeholder{
      color: $color-white;
    }
    &.phone{
      width: 100%;
      margin-left: 0;
      display: inline-block;
    }
    &.has-error{
      border: transparent !important;
      background: linear-gradient(to bottom, rgba(255,255,255,.1), rgba(255,255,255,.4)), url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-check-x.svg) 11px 10px ;
      padding-left: 40px;
      // background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-check-white.svg);
      background-repeat: no-repeat;
    }
  }
  .form-project{
    .prefix-phone{
      border: thin solid $color-white;
      border-radius: 0;
      background: transparent;
      color: $color-white;
      &:after{
        border-color: $color-white;
      }
      .list{
        background-color: rgba(255,255,255,.5);
        border-radius: 0;
        border: thin solid #fff;
        margin: 0;
        margin-left: -1px;
      }
      .option:hover, .option.focus, .option.selected.focus {
        background-color: rgba(255,255,255,.25);
      }
    }
    .box-options{
      li{
        border:thin solid $color-white;
        width: 150px;
        height: 100px;
        margin-bottom: 15px;
        position: relative;
        opacity: .65;
        &:hover, &:focus{          
          a{
            &:after{
              opacity: 1;
            }
          }
        }
        a{
          background: transparent;
          border-radius: 0;
          border:0;
          width: 100%;
          height: auto;
          line-height: 100%;
          text-align: right;
          padding: 10px;
          position: relative;
          &:hover{
            box-shadow: none;
          }
          img{
            vertical-align: initial;
            min-width: 30px;
            height: 30px;
          }
          &:after{
            bottom: auto;
            right: 10px;
            top: 10px;
            left: auto;
          }
        }
        &.active{
          background: linear-gradient(to bottom, rgba(255,255,255,.2), rgba(255,255,255,.5));
          border-color: transparent;
          opacity: 1;
          a{
             box-shadow: none;
          }
        }
        &:hover{
          opacity: 1;
        }
        span{
          line-height: 18px;
          display: block;
          margin-top: 10px;
          max-width: 100px;
          position: absolute;
          bottom: 8px;
          left: 10px;
          color:$color-white;
        }
      }
    }
  } 
    
  .area-drag, .dropboxfile{
    border: thin solid $color-white;
    border-radius: 0;
    background: transparent;
    color: $color-white;
    &:hover, &:focus{
      color: $color-white;
    }
  }
  .step-btns{
    a{
      color: $color-white;
    }
  }
}
.section-grey{
  background: #f4f4f4;
}
.bg-color-gradient{
  background: rgba(123,118,198,1); /* fallback for old browsers */
  background: -webkit-linear-gradient(55deg, rgba(7,206,107,1) 0%, rgba(123,118,198,1) 100%); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(55deg, rgba(7,206,107,1) 0%, rgba(123,118,198,1) 100%);
  min-height: 100vh;
}
.page-notification{
  background: $color-blue-bg;
  text-align: center;
  min-height: 100vh;
  .form-project{
    margin: auto;
    margin-top: 15px;
    max-width: 500px;
    label, p{
      color: $color-white;
    }
  }
}

.fa-remove:before, .fa-close:before, .fa-times:before {
    content: '';
    display: block;
    background: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-close.svg) no-repeat center;
    background-size: cover;
    width: 12px;
    height: 12px;
}


.pulses {
  position: relative;
  max-width: 270px;
  .pulse{
    content: '';
    display: block;
    width: 350px;
    height: 350px;
    background: rgba(255,255,255,0.02);
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 50%;
    margin-left: 50%;
    left: -175px;
    position: absolute;
    bottom: -40px;
    transition: none;
    transform: scale(0.1, 0.1);
    opacity: 0;
    animation: pulses 3000ms ease-out infinite;
    &:nth-of-type(2) {
      animation-delay: 1000ms;
    }
    &:nth-of-type(3) {
      animation-delay: 2000ms;
    }
  }
  img{
    position: relative;
    z-index: 1;
    max-width: 100%;
  }
}
.sv_position{
  position: relative;
  .pulses{
    transform: translateY(90px);
  }
}

@keyframes pulses {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.25, 1.25);
    opacity: 0;
  }
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
    animation: 1s linear 0s normal none infinite running bi-gradient;
    background: transparent linear-gradient(to right,#6cc9ff,#ff6779,#fcc645,#ff6779,#6cc9ff,#ff6779) repeat scroll 0 0/500% auto;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 3px;
    z-index: 99999;
}
@-moz-keyframes bi-gradient{0%{background-position:0 0}100%{background-position:100% 0}}
@-webkit-keyframes bi-gradient{0%{background-position:0 0}100%{background-position:100% 0}}
@keyframes bi-gradient{0%{background-position:0 0}100%{background-position:100% 0}}

.preload-blocker{
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s cubic-bezier(.25,.46,.45,.94),visibility .5s cubic-bezier(.25,.46,.45,.94);
  &.open{
    opacity: 1;
    visibility: visible;
  }
}