.section-pricing{
  padding-right:0;
  overflow:hidden;
  .section-heading{
    margin:40px 0 30px;
  }
}
.price-list{
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  margin-right:20px;
  .pricing{
    flex: 1 0 30%;
    display: flex;
    align-items: flex-start;
    .pricing-logo{
      flex: 1 0 64px;
      height: 64px;
      width: 64px;
      line-height: 64px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 5px 11px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
    .pricing-content{
      color: #212121;
      padding: 0 30px 30px;
      font-size:13px;
      font-weight:400;
      h3{
        font-size: 24px;
        font-weight: 500;
        margin: 16px 0;
      }
      ul{
        list-style: none;
        padding:0;
        margin:0 0 16px;
      }
      .pricing-box{
        background:#fff;
        margin-right: -15px;
        margin-left: -15px;
        padding:10px 15px;
        border-radius:4px;
        h5{
          margin: 15px 0 0;
        }
      }
      .pricing-notes{
        margin-top:16px;
        margin-bottom:16px;
        p{
          margin-bottom:0;
        }
      }
    }
  }
}


/*design update*/
.service-boxes{
  display: block;
  .service-col{
    display: block;
    vertical-align: middle;
    width: 100%;
    margin-bottom: 25px;
    &:last-child{
      margin-bottom: 0;
    }
    .service-price{
      background: $color-white;
      border-radius: 4px;
      overflow: hidden;
    }
      .service-title{
        padding: 15px;
        border-bottom: thin solid rgba(44, 177, 136, .2);
        h5{
          margin: 0;
          text-align: center;
          font-size: 18px;
          color: $color-yellow;
          font-weight: normal;
          line-height: 22px;
          img{
            width: 20px;
            margin-right: 10px;
            margin-top: -5px;
          }
        }
      }
      .service-desc{
        padding: 20px 20px 10px;
        p{
          margin-bottom: 25px;
          &.bigger{
            font-size: 15px;
            line-height: 22px;
            font-weight: $font-medium;
          }
        }
        .price-box{
          label{
            font-weight: $font-light;
          }
          h5{
            margin-top: 0;
            font-size: 25px;
            margin-bottom: 25px;
            span{
              font-weight: normal;
              font-style: $font-light;
            }
          }
        }
      }
      .service-footer{
        background: #F5F9FC;
        .button{
          background: transparent;
          color: $text-color;
          display: block;
          padding: 20px;
          text-transform: uppercase;
          font-weight: $font-medium;
          .fa{
            margin-left:  10px;
          }
        }
      }
    .service-get{
      background: #2D2B54;
      border-radius: 4px;
      overflow: hidden;
      text-align: center;
      .service-title{
        border-bottom: thin solid rgba(44, 177, 136, .1);
        h5{
          color: #78BAFF;
        }
      }
      .service-desc{
        p{
          color: #A7B6C4;
          &.bigger{
            color: $color-white;
            font-weight: normal;
          }
        }
      }
      .service-footer{
        background: #343367;
        .button{
          color: #A7B6C4;
        }
      }
    }
  }
}
.pricing-boxes{
    height: 1250px;
    position: relative;
    .service-boxes {
      display: block;
      position: absolute;
      top: 0;
      margin: 0 auto;
      right: 25px;
      left: 25px;
  }
}
