// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.headermenu{
	padding: 15px;
	background-color: transparent;
	position: fixed;
  z-index: 1000;
  width: 100%;
  z-index: 100;
  border-bottom: 0;
  transition: background .3s ease-out,padding .5s ease;
  max-width: 768px;
  &.opened{
    background-color: $color-white;
    position: fixed;
  }
  &.scrolled{
    background: rgba(255, 255, 255, 0.87);
    padding: 10px 15px;
    height: 65px;
    .logo{
      margin-top: 9px;
    }
    .menu{
      margin-top: 2px;
    }
  }
	.logo{
		float: left;
    margin-top: 9px;
    img{
      max-width: 120px;
      height: 20px;
    }
	}
	.menu{
    float: right;
    margin-top: 3px;
		ul{
			list-style: none;
			padding: 0;
      margin: 0;
      line-height:normal;
			li{
				display: inline-block;
				position: relative;
				&.active{
					a{
						font-weight: $font-medium;
            color: $orange !important;
					}
					// &:after{
					// 	content: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-arrow.svg);
					// 	position: absolute;
					// 	left: 77%;
					// 	transform: scale(2, 1.4) translate(-50%, 0%);
					// }
				}
				&.get-free-quote{
					a{
						background: #ff4831;
            padding: 3px 13.35px;
            font-weight: 500;
            color: #fff;
            position: relative;
            overflow: hidden;
            line-height: 34px;
            border-radius: 50px;
            text-transform: uppercase;
            &:hover, &:focus{
              background: #353535;
              color: #fff;
            }
					}
				}
				a{
					text-decoration: none;
					color: #212121;
					font-size: 14px;
					text-transform: none;
					display: block;
					padding: 0 10px;
					font-family: 'Seravek';
					font-weight: normal;
					letter-spacing: .5px;
          color: #b1b1b1;
				}
			}
		}
	}
  &.in-gradient{
    &.opened{
      background-color: rgba(255,255,255,.2);
    }
    .menu{
      ul{
        li{
          a{
            color: $color-white;
          }
        }
      }
    }
  }
}
.hero{
  background-size: cover;
  min-height: 100vh;
  position: relative;
  overflow:hidden;
  &.section-1{
    .min-section{
      hero-rocket{
        z-index: 1;
        position: relative;
      }
      .container{
        z-index: 2;
        position: relative;
        bottom:2em;
      }
    }
  }
  .img-hero{
    position: relative;
    z-index: 1;
    height: 60vh;
    bottom: -20em;
    left: -32em;
    top: auto;
    transition: all .8s ease-in-out;
    @media (min-width: 768px){
      height: 65vh;
    }
    .floating{
      height: 100%;
      width: 100%;
      &.floating-animation{
        transform: translatey(0px);
        animation: float 4s ease-in-out infinite;
        -webkit-animation: float 4s ease-in-out infinite;
        -moz-animation: float 4s ease-in-out infinite;
        -o-animation: float 4s ease-in-out infinite;
      }
    }
    img{
      position: absolute;
      bottom: -3em;
      left: -10em;
      margin-left: 0;
      max-width: 205vw;
      transition: all .3s ease;
      &.psw1{
        z-index: 4;
      }
      &.psw2{
        z-index: 5;
        width: 220%;
        max-width: 250vw;
      }
      @media (min-width: 375px){
        bottom: -6em;
        left: -13em;
      }
      @media (min-width: 768px){
        max-width: 1200px;
        bottom: -8em;
        left: -20em;
      }
      @media (max-width: 400px) and (min-width: 330px){
        left: -11em;
      }
      &.a{
        z-index: 5;
      }
      &.b{
        z-index: 2;
      }
    }
  }
	.hero-title{
		display: inline-block;
		vertical-align: top;
		margin-top: 8em;
		margin-left: 0;
    width: 100%;
    text-align: left;
		h2{
			font-size: 50px;
			font-family: $font-graphik;
			font-weight: $font-semibold;
			color: #fff;
      margin: 0;
      margin-bottom: 15px;
      line-height: 52px;
      text-align: right;
      &.title.title--style-1{
        margin-bottom: 0;
      }
      &.letter-effect{
        span{
          color: $orange;
        }
      }
      .yellow-text-gradient{
        font-size:inherit;
        line-height:inherit;
        text-transform: none;
      }
		}
    h4{
      font-weight: $font-regular;
      line-height: normal;
      font-size: 14px;
      color: #212121;
      font-family: $font-seravek;
      margin-bottom: 0;
      text-transform: uppercase;
      margin-bottom: 10px;
      margin-top: 10px;
    }
	}
}
.section.section-our-works{
  padding-left: 0;
}
.our-works{
	padding-bottom:2em;
  padding-top: 0;
  margin-top: -2em;
	.our-works-list{
		.our-item{
			width: 151.84px;
			background: transparent;
			margin-right: 20px;
			float: left;
      z-index: 10;
      display: inline-block;
      @media (min-width: 768px){
        width: 191.84px;
      }
			.tilter__caption{
  				padding: 20px;
  			}
  			.tilter__deco--overlay{
  				// background-image: linear-gradient(45deg,rgba(169, 166, 166, 0.4),rgba(111, 111, 111, 0.4),rgba(167, 167, 167, 0.4));
  				background-image: none;
				display: block;
				background: #000;
				opacity: 0.1;
  			}
  			.tilter__title, .tilter__description{
  				font-family: 'Seravek';
				font-weight: 500;
				color: #fff;
				font-size: 18px;
				text-transform: capitalize;
				letter-spacing: 1px;
				span{
					text-transform: none;
				}
  			}
  			.tilter__description{
  				font-style: italic;
				margin-top: 5px;
				font-weight: 400;
				letter-spacing: normal;
				span.com{
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					position: relative;
					top: -5px;
					margin-left: 5px;
					i{
						position: relative;
						top: 2px;
						margin-left: 12px;
						transform: scale(2, 1.5);
					}
				}
  			}
		}
	}
	.columns{
		.our-works-desc, .our-works-content{
			// float: left;
		}
		.our-works-desc{
			// width: 35%;
      h2{
        margin-bottom: 5px;
        line-height: 45px;
      }
			p{
				color: #fff;
				font-size: 13px;
        margin-bottom: 15px;
        line-height: 20px;
			}
			.btn{
				max-width: 180px;
				text-align: center;
			}
		}
		.our-works-content{
			width: 100%;
      position: relative;
			.our-works-list{
				width: 100vw;
				padding-left: 0em;
        // height: 50vh;
        position: relative;
        // overflow: hidden;
			}
      .our-works-action{
        position: absolute;
        top: 0;
        width: 100%;
        button{
          height: 496px;
          position: absolute;
          width: 150px;
          border: none;
          background-color: transparent;
          &.our-prev{
            left: 0;
            cursor: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/right-arrow.png), pointer;
          }
          &.our-next{
            right: calc(100% - 114%);
            cursor: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/left-arrow.png), pointer;
          }
        }
      }
		}
	}
	&.content-page{
		padding-top: 0px;
		padding-bottom: 50px;
	}
}
.yellow-text-gradient{
  font-size: 50px;
  line-height: 52px;
	font-family: $font-graphik;
	font-weight: $font-semibold;
	text-transform: capitalize;
	background: -webkit-linear-gradient(to right, #FFA600, #ffce11);
	background: linear-gradient(to right, #FFA600, #ffce11);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  .grey{
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(to right, #d0d0d0, #d0d0d0);
    background: linear-gradient(to right, #d0d0d0, #d0d0d0);
  }
}

.popup-gift{
	.dialog-box{
		max-width: 500px;
    padding: 40px 20px 20px 20px;
		h1{
			font-size: 42px;
			font-weight: 500;
			color: $color-dark;
			max-width: 300px;
			margin: 0;
			margin-bottom: 15px;
    		line-height: 42px;
		}
		p{
			font-size: 14px;
			font-weight: normal;
			color: $color-dark;
			margin-bottom: 10px;
      line-height: 22px;
      &.note-pop{
        margin-bottom: 0;
      }
      @media (min-width: 375px){
        text-align: center;
      }
      small{
        font-size: 13px;
        font-style: italic;
      }
		}
		.gift-form{
			margin-bottom: 20px;
      position: relative;
      .progress_circle{
        display: none;
      }
      &.progress{
        .progress_circle{
          display: block;
          top: 11px;
          left: 8px;
        }
        .email{
          padding-left: 35px;
        }
      }
      &.success{
        .email{
          padding-left: 35px;
        }
        &:before{
          content: '';
          position: absolute;
          background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-check.svg);
          width: 20px;
          height: 20px;
          background-size: 100%;
          background-position: right center;
          top: 11px;
          left: 10px;
        }
      }
      &.invalid{
        .email{
          padding-left: 35px;
        }
        &:before{
          content: "";
          position: absolute;
          background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-close.svg);
          background-color: #2e3038;
          border-radius: 100%;
          width: 20px;
          height: 20px;
          padding: 5px;
          background-repeat: no-repeat;
          background-size: 10px;
          background-position: center;
          top: 11px;
          left: 10px;
        }
      }
      &.registered{
        .email{
          padding-left: 35px;
        }
        &:before{
          content: '';
          position: absolute;
          background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-smile.svg);
          width: 20px;
          height: 20px;
          background-size: 100%;
          background-position: right center;
          top: 11px;
          left: 10px;
        }
      }
			.email{
				width: 100%;
				// max-width: 220px;
  			height: 40px;
				line-height: 40px;
				font-size: 13px;
				color: $color-dark;
				background-color: $color-white;
				border-radius: 0px;
				border: thin solid $color-border;
				padding: 0 10px;
				display: block;
				margin-bottom: 15px;
        &.has-error{
          border: 1px solid red;
        }
        @media (min-width: 375px){
          max-width: 100%;
        }
			}
      @media (min-width: 400px){
        button{
          width: 100%;
        }
      }
		}
		
	}
}
.gift-sticker{
  position: static;
  background: #38d82c; /* fallback for old browsers */
  // background: -webkit-linear-gradient(-45deg, rgba(7,206,107,1) 0%, rgba(123,118,198,1) 100%);
  // background: linear-gradient(-45deg, rgba(7,206,107,1) 0%, rgba(123,118,198,1) 100%);
  background: -webkit-linear-gradient(-87deg,#38d82c,#f5d924);
  background: linear-gradient(-87deg,#38d82c,#f5d924);
  width: 212px;
  height: 212px;
  border-radius: 100%;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  display: table;
  margin-bottom: 35px;
  .gift-holder{
    display: table-cell;
    vertical-align: middle;
    color: $color-white;
  }
  h2{
    font-weight: $font-medium;
    text-transform: none;
    font-size: 36px;
    margin: 0;
    line-height: 1;
    &.yellow-text-gradient{
      color: #303030;
      -webkit-text-fill-color: inherit;
    }
  }
  h5{
    font-style: italic;
    font-weight: $font-x-light;
    font-size: 23px;
    margin: 0;
    line-height: 1;
  }
}
h2.normal{
	font-size: 41px;
	font-family: $font-seravek;
	font-weight: $font-medium;
	text-transform: uppercase;
  &.grey{
    color: #d0d0d0;
  }
}
.testi-wrapper{
  padding-top: 6em;
  .testi-text{
    padding: 0 20px;
  }
}
.people-block {
  padding: 0 0 30px;
  text-align: center;
  margin-left: -40px;
  margin-right: -40px;
  h2{
	max-width: 100%;
	margin: auto;
	margin-bottom: -150px;
    position: relative;
    text-align: center;
  }
  .people-wrapper {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: visible;
    margin: 0 auto;
    @media (min-width: 768px){
      height: 600px;
    }
    .crop-wrapper {
      position: absolute;
		left: 0;
		right: 0;
		top: 0;
		margin: auto;
		width: 100%;
		height: 200px;
		text-align: center;
		-webkit-transform: scale(2);
    transform: translateY(0%) translateX(-40%) scale(.55);
    @media (min-width: 768px){
      transform: translateY(-7%) translateX(-10%) scale(.7);
    }
    }
    &:before {
      content: "";
      position: absolute;
      left: -1500px;
      right: -1500px;
      top: 0;
      height: 200px;
      //background: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0));
      top: -100px;
      left: -500px;
      right: -500px;
      height: 100px;
    }
    &:after {
      content: "";
      position: absolute;
      left: -1500px;
      right: -1500px;
      bottom: 0;
      bottom: -100px;
      height: 200px;
      //background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
      left: -500px;
      right: -500px;
      height: 100px;
    }
    .shapes {
      position: absolute;
      width: 2062px;
      height: 2063px;
      margin-left: -1031px;
      margin-top: -1031px;
      z-index: -1;
      background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/shapes.png);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 2062px 2063px;
      transition: opacity .8s ease-out;
      opacity: 0.35;
      width: 1000px;
      height: 1000px;
      margin-left: -500px;
      margin-top: -500px;
      background-size: 1000px 1000px;
      .gradient {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: radial-gradient(ellipse at center, hsla(0, 0%, 100%, 0) 0, hsla(0, 0%, 100%, 0) 40%, #fff 70%, #fff 100%);
      }
      &.hidden {
        opacity: 0;
      }
      &.fade-out {
        transition: all 1.25s ease-out;
        opacity: 0;
      }
    }
    .people {
      position: absolute;
      opacity: 0;
      border-radius: 50%;
      background: #fff;
      opacity: 0;
      &.interactive {
        cursor: pointer;
		    opacity: 0;
        &:before {
          content: "";
          position: absolute;
          left: -80px;
          top: -80px;
          border-radius: 50%;
          bottom: -80px;
          right: -80px;
          content: "";
          position: absolute;
          left: -10px;
          top: -10px;
          border-radius: 50%;
          bottom: -10px;
          right: -10px;
        }
        .people-photo {
          opacity: 1;
          background-color: #fff;
          &:before{
            content: '';
            background: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/hover-people.png) no-repeat;
            background-size: cover;
            opacity: 0;
            transform: translateX(-29%) translateY(-27.3%);
            transition: opacity .32s ease-in-out;
            display: block;
            width: 235%;
            height: 235%;
          }
        }
        &:hover{
          .people-photo {
            box-shadow: 0 0 0 3px hsla(0,0%,100%,.3);
            -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.5);
            box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.5);
            &:before{
              opacity: 1;
            }
          }
          .people-popup {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
            visibility: inherit;
            transition: opacity .32s ease-in-out, transform .32s ease-in-out;
            pointer-events: all;
            &.position-left {
              -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
            }
          }
        }
      }
      &.active {
        .people-photo {
          box-shadow: 0 0 0 3px #19b5c1 !important;
          transition: all 0.65s 0.1s;
        }
        .people-popup {
          opacity: 1;
          -webkit-transform: none !important;
          transform: none !important;
          visibility: inherit;
          transition: opacity .32s ease-in-out, transform .32s ease-in-out;
          pointer-events: all;
        }
      }
      .people-photo {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 100% 100%;
        opacity: 0.35;
        transition: all 0.15s;
        background-color: #000;
      }
      .people-popup {
        position: absolute;
        top: 140%;
        margin-top: 0;
        pointer-events: none;
        width: 420px;
        max-width:400px;
        box-sizing: border-box;
        padding: 31px 32px 30px;
        z-index: 1;
        background: #fff;
        box-shadow: 0 6px 20px 0 rgba(53, 67, 83, 0.2);
        text-align: left;
        text-transform: none;
        cursor: default;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
        font-weight: 400;
        opacity: 0;
        visibility: hidden;
        transition: all 0.32s ease-in-out;
        transform: scale(1.5) !important;
        &.position-left {
          right: 100%;
          left:auto;
          margin-right: 0;
          -webkit-transform: translateX(-90%);
          transform: translateX(-90%);
          .people-corner-wrapper {
            position: absolute;
            top: -9px;
            width: 20px;
            height: 20px;
            right: 15px;
            clip: rect(-99px, 99px, 99px, 10px);
            transform: rotate(-90deg);
            .corner {
              position: absolute;
              width: 14.14213px;
              height: 14.14213px;
              left: 3px;
              top: 3px;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              background: #fff;
              box-shadow: 6px 6px 20px 0 rgba(53, 67, 83, 0.2);
            }
          }
        }
        &.position-right {
          left: 90%;
          margin-left: 0;
          -webkit-transform: translateX(-14px);
          transform: translateX(-14px);
          .people-corner-wrapper {
            position: absolute;
            top: -7px;
            width: 20px;
            height: 20px;
            left: 15px;
            clip: rect(-99px, 10px, 99px, 0);
            clip: rect(-99px, 10px, 99px, -99px);
            transform:rotate(90deg);
            .corner {
              position: absolute;
              width: 14.14213px;
              height: 14.14213px;
              left: 3px;
              top: 3px;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              background: #fff;
              box-shadow: 6px 6px 20px 0 rgba(53, 67, 83, 0.2);
            }
          }
        }
        &.position-center {
          left: -20% ;
          max-width: 350px;
          margin-left: 0;
          -webkit-transform: translateX(-14px);
          transform: translateX(-14px);
          .people-corner-wrapper {
            position: absolute;
            top: -7px;
            width: 20px;
            height: 20px;
            left: 20px;
            clip: rect(-99px, 10px, 99px, 0);
            clip: rect(-99px, 10px, 99px, -99px);
            transform:rotate(90deg);
            .corner {
              position: absolute;
              width: 14.14213px;
              height: 14.14213px;
              left: 2px !important;
              top: -95px !important;
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              background: #fff;
              box-shadow: 6px 6px 20px 0 rgba(53, 67, 83, 0.2);
            }
          }
        }
    

        &.position-left .people-corner-wrapper, &.position-right .people-corner-wrapper {

        }
        &.position-left .people-corner-wrapper .corner, &.position-right .people-corner-wrapper .corner {
          position: absolute;
          width: 10px;
          height: 10px;
          left: 4px;
          top: 2px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          background: #fff;
          box-shadow: 6px 6px 20px 0 rgba(53, 67, 83, 0.2);
        }
        left: 50%;
        margin-left: -80px;
        // width: 160px;
        border-radius: 4px;
        padding: 15px 20px 10px;
        .name {
          font-size: 24px;
          line-height: 34px;
          color: #333;
          letter-spacing: -.38px;
          font-weight: 700;
          font-size: 16px;
          line-height: 34px;
          letter-spacing: -0.25px;
        }
        .feedback {
          margin-top: 10px;
          font-size: 18px;
          line-height: 30px;
          color: #333;
          letter-spacing: -0.8px;
          margin-top: 1px;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0;
        }
      }

      &.people-15.interactive{
        .people-popup{
          left:auto;
          right:80%;
          .people-corner-wrapper{
            left: auto;
            right: 20px;
          }
        }
      }
      &.people-26.interactive{
        .people-popup{
          left: -100%;
          right:auto;
          margin-left:0;
          max-width: 300px;
          .people-corner-wrapper{
            left: 50%;
            right: auto;
            
          }
        }
      }
    }
    &.inview{
      .people{
        opacity: .5;
        -webkit-transform: none;
        transform: none;
        &.interactive{
        opacity: 1;
        }
      }
    }
    .people-1 {
      left: -135px;
      top: 306px;
      width: 40px;
      height: 40px;
      transition: all 729ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.39s;
      -webkit-transform: translate(-309px, -20px);
      transform: translate(-309px, -20px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/blackhairsalon.png);
      }
    }
    .people-2 {
      left: -6px;
      top: 309px;
      width: 80px;
      height: 80px;
      transition: all 0.68s cubic-bezier(0.25, 0.46, 0.45, 0.94) 462ms;
      -webkit-transform: translate(-296px, -190px);
      transform: translate(-296px, -190px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/seiko.png);
      }
    }
    .people-3 {
      left: 620px;
      top: 450px;
      width: 57px;
      height: 57px;
      transition: all 634ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 315ms;
      -webkit-transform: translate(-28px, -177px);
      transform: translate(-28px, -177px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/bodyimage.png);
      }
    }
    .people-4 {
      left: 350px;
      top: 260px;
      width: 168px;
      height: 168px;
      transition: all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.55s;
      -webkit-transform: translate(4px, -144px);
      transform: translate(4px, -144px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/bluebird.png);
      }
    }
    .people-5 {
      left: 819px;
      top: 302px;
      width: 80px;
      height: 80px;
      transition: all 801ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 447ms;
      -webkit-transform: translate(193px, -201px);
      transform: translate(193px, -201px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/lovepink.png);
      }
    }
    .people-6 {
      left: 650px;
      top: 335px;
      width: 123px;
      height: 123px;
      transition: all 0.76s cubic-bezier(0.25, 0.46, 0.45, 0.94) 335ms;
      -webkit-transform: translate(52px, -228px);
      transform: translate(52px, -228px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/rumah360.png);
      }
    }
    .people-7 {
      left: 1052px;
      top: 333px;
      width: 42px;
      height: 42px;
      transition: all 828ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 353ms;
      -webkit-transform: translate(127px, -110px);
      transform: translate(127px, -110px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/sukujempol.png);
      }
    }
    .people-8 {
      left: 1200px;
      top: 299px;
      width: 78px;
      height: 78px;
      transition: all 605ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 527ms;
      -webkit-transform: translate(637px, -108px);
      transform: translate(637px, -108px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/printshop.png);
      }
    }
    .people-9 {
      left: 1443px;
      top: 332px;
      width: 52px;
      height: 52px;
      transition: all 873ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.54s;
      -webkit-transform: translate(400px, -109px);
      transform: translate(400px, -109px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/mfl.png);
      }
    }
    .people-10 {
      left: -262px;
      top: 404px;
      width: 54px;
      height: 54px;
      transition: all 742ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 374ms;
      -webkit-transform: translate(-497px, -90px);
      transform: translate(-497px, -90px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/partygaga.png);
      }
    }
    .people-11 {
      left: -135px;
      top: 407px;
      width: 62px;
      height: 62px;
      transition: all 0.83s cubic-bezier(0.25, 0.46, 0.45, 0.94) 383ms;
      -webkit-transform: translate(-36px, -84px);
      transform: translate(-36px, -84px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/studio13.png);
      }
    }
    .people-12 {
      left: 100px;
      top: 356px;
      width: 147px;
      height: 147px;
      transition: all 688ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 515ms;
      -webkit-transform: translate(-262px, -107px);
      transform: translate(-262px, -107px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/misteraladin2.png);
      }
    }
    .people-13 {
      left: 309px;
      top: 432px;
      width: 59px;
      height: 59px;
      transition: all 741ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 378ms;
      -webkit-transform: translate(-24px, -44px);
      transform: translate(-24px, -44px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/moodboard-m.png);
      }
    }
    // .people-14 {
    //   left: 580px;
    //   top: 430px;
    //   width: 57px;
    //   height: 57px;
    //   transition: all 623ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 401ms;
    //   -webkit-transform: translate(179px, -26px);
    //   transform: translate(179px, -26px);
    //   .people-photo {
    //     background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/people-14.jpg);
    //   }
    // }
    .people-15{
      left: 650px;
      top: 570px;
      width: 68px;
      height: 68px;
      transition: all 739ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 534ms;
      -webkit-transform: translate(179px, -96px);
      transform: translate(179px, -96px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/primavit.png);
      }
    }
    .people-16 {
      left: 971px;
      top: 409px;
      width: 78px;
      height: 78px;
      transition: all 647ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 493ms;
      -webkit-transform: translate(375px, -68px);
      transform: translate(375px, -68px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/blackhairsalon.png);
      }
    }
    .people-17 {
      left: 1094px;
      top: 414px;
      width: 135px;
      height: 135px;
      transition: all 735ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.32s;
      -webkit-transform: translate(352px, -97px);
      transform: translate(352px, -97px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/bodyshape.png);
      }
    }
    .people-18 {
      left: 1304px;
      top: 368px;
      width: 96px;
      height: 96px;
      transition: all 0.78s cubic-bezier(0.25, 0.46, 0.45, 0.94) 505ms;
      -webkit-transform: translate(825px, -95px);
      transform: translate(825px, -95px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/taskit.png);
      }
    }
    .people-19 {
      left: 1421px;
      top: 481px;
      width: 80px;
      height: 80px;
      transition: all 718ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 411ms;
      -webkit-transform: translate(124px);
      transform: translate(124px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/moodboard-m.png);
      }
    }
    .people-20 {
      left: 1529px;
      top: 377px;
      width: 112px;
      height: 112px;
      transition: all 891ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.54s;
      -webkit-transform: translate(8px, -41px);
      transform: translate(8px, -41px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/blackhairsalon.png);
      }
    }
    .people-21 {
      left: -261px;
      top: 508px;
      width: 92px;
      height: 92px;
      transition: all 767ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 583ms;
      -webkit-transform: translate(-625px, 23px);
      transform: translate(-625px, 23px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/taskit.png);
      }
    }
    .people-22 {
      left: -59px;
      top: 486px;
      width: 72px;
      height: 72px;
      transition: all 547ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 329ms;
      -webkit-transform: translate(-406px, 15px);
      transform: translate(-406px, 15px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/doku.png);
      }
    }
    .people-23 {
      left: 59px;
      top: 542px;
      width: 80px;
      height: 80px;
      transition: all 761ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 374ms;
      -webkit-transform: translate(-313px, 92px);
      transform: translate(-313px, 92px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/louvreluxe.png);
      }
    }
    .people-24 {
      left: 213px;
      top: 520px;
      width: 67px;
      height: 67px;
      transition: all 854ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 422ms;
      -webkit-transform: translate(-36px, 42px);
      transform: translate(-36px, 42px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/blinkcharm.png);
      }
    }
    .people-25 {
      left: 353px;
      top: 585px;
      width: 67px;
      height: 67px;
      transition: all 749ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 587ms;
      -webkit-transform: translate(-10px, 37px);
      transform: translate(-10px, 37px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/moodboard.png);
      }
    }
    .people-26 {
      left: 463px;
      top: 520px;
      width: 111px;
      height: 111px;
      transition: all 659ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 365ms;
      -webkit-transform: translate(10px, 56px);
      transform: translate(10px, 56px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/deltadunia.png);
      }
    }
    .people-27 {
      left:980px;
      top: 542px;
      width: 67px;
      height: 67px;
      transition: all 503ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 486ms;
      -webkit-transform: translate(201px, 100px);
      transform: translate(201px, 100px);
      display: none;
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/blackhairsalon.png);

      }
    }
    .people-28 {
      left: 842px;
      top: 483px;
      width: 78px;
      height: 78px;
      transition: all 757ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 534ms;
      -webkit-transform: translate(14px, 7px);
      transform: translate(14px, 7px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/atzev.png);
      }
    }

    .people-29 {
      left: 970px;
      top: 530px;
      width: 98px;
      height: 98px;
      transition: all 676ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 439ms;
      -webkit-transform: translate(372px, 80px);
      transform: translate(372px, 80px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/partygaga.png);
      }
    }
    .people-30 {
      left: 1140px;
      top: 609px;
      width: 60px;
      height: 60px;
      transition: all 717ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 337ms;
      -webkit-transform: translate(248px, 135px);
      transform: translate(248px, 135px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/maubelajarapa.png);
      }
    }
    .people-31 {
      left: 1251px;
      top: 516px;
      width: 96px;
      height: 96px;
      transition: all 756ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 434ms;
      -webkit-transform: translate(182px, 72px);
      transform: translate(182px, 72px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/lmensity.png);
      }
    }
    .people-32 {
      left: 1401px;
      top: 614px;
      width: 64px;
      height: 64px;
      transition: all 812ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 596ms;
      -webkit-transform: translate(574px, 80px);
      transform: translate(574px, 80px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/blinkcharm.png);
      }
    }
    .people-33 {
      left: 1558px;
      top: 543px;
      width: 102px;
      height: 102px;
      transition: all 788ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 589ms;
      -webkit-transform: translate(438px, 1px);
      transform: translate(438px, 1px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/moodboard-m.png);
      }
    }
    .people-34 {
      left: -125px;
      top: 598px;
      width: 55px;
      height: 55px;
      transition: all 888ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 482ms;
      -webkit-transform: translate(-27px, 146px);
      transform: translate(-27px, 146px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/bodyimage.png);
      }
    }
    .people-35 {
      left: -6px;
      top: 628px;
      width: 48px;
      height: 48px;
      transition: all 639ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 361ms;
      -webkit-transform: translate(-334px, 87px);
      transform: translate(-334px, 87px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/lmensity.png);
      }
    }
    .people-36 {
      left: 256px;
      top: 638px;
      width: 39px;
      height: 39px;
      transition: all 672ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 525ms;
      -webkit-transform: translate(-107px, 50px);
      transform: translate(-107px, 50px);
      display: none;
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/misteraladin.png);
      }
    }
    .people-37 {
      left: 576px;
      top: 630px;
      width: 48px;
      height: 48px;
      transition: all 707ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 413ms;
      -webkit-transform: translate(71px, 120px);
      transform: translate(71px, 120px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/framed.png);
      }
    }
    .people-38 {
      left: 781px;
      top: 608px;
      width: 69px;
      height: 69px;
      transition: all 564ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 586ms;
      -webkit-transform: translate(51px, 58px);
      transform: translate(51px, 58px);
      .people-photo {
        background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/clients/butuhbelanja.png);
      }
    }


  }
}

.work-easy{
	text-align: center;
    padding-bottom: 150px;
	h3{
		font-size: 18px;
		color: #fff;
		margin: 0;
	}
	p{
		font-size: 13px;
		color: #fff;
	}
}
.home{
  overflow: hidden;
  &.top-padding{
    padding-top: 2em;
  }
}
.kodeku-page{
  &>.home{
    overflow: hidden;
    &.top-padding{
      padding-top: 1em;
    }
  }
}


.bg-backdrop{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(152,152,152,.5);
  z-index: 9;
  top: 0;
  display: none;
  &.show{
    display: block;
  }
}

.set-goal{
  padding-bottom: 150px;
  &.container{
    @media (max-width: 1150px) and (min-width: 1024px){
      min-width: 100%;
    }

  }
  .row{
    .col{
      width: 50%;
    }
  }
	h3{
		font-size: 18px;
		color: #fff;
		margin: 0;
    margin-bottom: 10px;
    max-width: 568px;
    span{
      font-size: 13px;
      float: right;
    }
	}
	p{
		font-size: 13px;
		color: #fff;
    margin: 15px 0 20px;
	}
  img{
    @media (max-width: 1150px){
      width: 100%;
    }
  }
}
.goal-progress{
  position:relative;
  height: 40px;
  line-height: 40px;
  background: #E1E1E1;
  text-align: right;
  padding: 0 10px;
  border-radius: 4px;
  .progress{
    display: block;
    background: $orange;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 40px;
    width:0%;
    z-index:1;
    border-radius:4px;
    transition: width 0.4s linear;
  }
  .text{
    position:relative;
    z-index:2;
  }
  &.goal-progress{
    .progress{
      width:100%;
      animation: progress 3s ease-in;
    }
  }
}

@keyframes progress{
  0%{
    width:0;
    border-radius:4px 4px 0 0;
  }
  100%{
    width:100%;
    border-radius:4px;
  }
}

/* Slideshow text-gradient*/
.service-anim.content {
    position: relative;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    margin-top: 25px;
    padding: 0;
}
[class*="letter"] {
    display: block;
    position: relative;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    white-space: pre;
}
.service-anim .slideshow,
.service-anim .slide {
	position: relative;
	width: 100%;
	height: 100%;
}

.service-anim .slide {
	padding: 0;
}
.service-anim .content {
    overflow: hidden;
}

.service-anim .slide {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	justify-content: flex-start;
}

.service-anim .slide:not(.slide--current) {
	// position: absolute;
	// top: 0;
	pointer-events: none;
	// opacity: 0;
}
.service-anim .actions{
  display: none;
}
.service-anim .letter-effect {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	justify-content: flex-end;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;

  // background: -webkit-linear-gradient(left, #FFF 20%, #FF0 40%, #FF0 60%, #FFF 80%);
  // background: linear-gradient(to right, #FFF 20%, #FF0 40%, #FF0 60%, #FFF 80%);
  // background-size: 200% auto;
  // color: #000;
  // background-clip: text;
  // text-fill-color: transparent;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // -webkit-animation: shine 1s linear infinite;
  //         animation: shine 1s linear infinite;
}
.tab-slider .track.track-home{
  left: 7px;
  top: 50px;
  z-index: 0;
  border-color: rgba(255, 255, 255, 0.25);
  width: 102vw;
  height: 102vw;
  @media (min-width: 768px){
    left: -9px;
    top: 73px;
    width: 105vw;
    height: 105vw;
  }
  // @media (max-width: 1024px) and (min-width: 769px){
  //   max-width: 500px;
  //   max-height: 500px;
  //   margin:0 auto;
  // }
  @media (min-width: 425px){top: 82px;}
  @media (max-width: 375px){top:22%;}
  @media (max-width: 320px){top: 50px;}
}
.rocket-title{
  position: relative;
  .rocket-list{
    position: absolute;
    right: 0;
    bottom: -5em;
    transition: all .4s ease;
    &.hide{
      opacity: 0;
    }
  }
  .construct-desc{
    text-align: right;
    margin-left: 0;
    float: right;
    a.with-arrow{
      float: right;
    }
  }
  h1{
    text-align: right;
    line-height: normal;
    margin-left: 0;
    white-space: nowrap;
    margin-bottom: .3em;
    float: right;
    font-size: 35px;
    text-transform: none;
  }
}
@-webkit-keyframes shine {
  to {
    background-position: 200% center;
  }
}
@keyframes shine {
  to {
    background-position: 200% center;
  }
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}
