.section-how-it-work{
  margin-bottom:0;
  .section-heading{
    font-weight: 500;
  }
}
.section-process{
  &.how-it-works-pg{
    padding-bottom: 4em;
    margin-top: -2em;
  }
}
.service{ 
  .this-is-header{
    padding:6em 1em 0em ;
  }
  .tab-slider .track.track-home{
    left:25px;
  }
  .testi-wrapper{
    padding-top:1em;
    padding-bottom: 4em;
  }
}