/**
 * Dialogs
 * --------------------------------------------------
 * A custom style for dialogs.
 */

 .dialog-wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    box-sizing: border-box;
    width: 100vw;
    transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1), visibility 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    display: table;
    height: 100vh;
    .dialog-overlay{
        background: transparent;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10000;
        position: fixed;
        transition: background 0.4s cubic-bezier(0.23, 1, 0.32, 1);
        // -webkit-transition-duration: 0.4s;
	    // transition-duration: 0.4s;
        // opacity: 0;
        // visibility: hidden;
        // -webkit-transition: opacity 0.3s;
        // transition: opacity 0.3s;
        // -webkit-backface-visibility: hidden;
        transform: translateX(0%);
        
    }
    .dialog-centerer{
        display: table-cell;
        vertical-align: middle;
        padding-left: 20px;
        padding-right: 20px;
        @media (max-width: 320px){
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .dialog-box{
        border-radius: 4px;
        position: relative;
        z-index: 10001;
        // opacity: 0;
        max-width: 650px;
        // min-width: 320px;
        margin: auto;
        background-color: $color-white;
		padding:40px;
        box-shadow: 0 5px 40px 0px rgba(80, 80, 80, 0.22);
        .dialog-title{
            font-size:17px;
            color: rgba(0,0,0,.87);
            text-align: center;
            margin: 0;
            margin-top: 10px;
        }
        p{
            font-size: 13px;
        }
        .close{
            width: 40px;
            height: 40px;
            background: $color-darker;
            color: $color-white;
            font-size:20px;
            line-height: 40px;
            text-align: center;
            display: block;
            border-radius: 100%;
            position: absolute;
            top: 20px;
            right: 20px;
            padding: 2px 0;
            .fa:before{
                width: 14px;
                height: 14px;
            }
        }
        &.slideInLeftBounce{
            &.inview{
                &.invert{
                    transform: translateX(-500%)!important;
                }
            }
        }
        .dialog-overlay{
            opacity: 0;
            visibility: hidden;
            transition: opacity 5s ease;
            transition-delay: 2s;
            &.inload{
                transform: translateX(-100%);
            }
        }
    }
    &.dialog-open{
            opacity: 1;
            visibility: visible;
        .dialog-overlay{
            background: rgba(231,231,231,.92);
        }
        .dialog-box{
            // -webkit-animation-duration: 0.4s;
            // animation-duration: 0.4s;
            // -webkit-animation-fill-mode: forwards;
            // animation-fill-mode: forwards;
            // -webkit-animation-name: anim-open;
            // animation-name: anim-open;
            // -webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
            // animation-timing-function: cubic-bezier(0.6,0,0.4,1);
        }
    }
    &.dialog-close{
        opacity: 0;
        visibility: hidden;
        transition: opacity 5s ease;
        // transition-delay: 2s;

        .dialog-overlay{
            opacity: 0;
            visibility: hidden;
            transition: opacity 5s ease;
            transition-delay: 2s;
            &.inload{
                transform: translateX(-100%);
            }
        }
        .dialog-box{
            -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            -webkit-animation-name: anim-close;
	        animation-name: anim-close;
            -webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
            animation-timing-function: cubic-bezier(0.6,0,0.4,1);
        }
    }
    .dialog-btn{
        display: block;
        border: 0;
        background: #fff;
        color: $color-dark;
        width:100%;
        font-size: 18px;
        padding: 15px;
    }
    &.logo-only{
        .dialog-overlay{
            background: #f5f5f5;
        }
        .dialog-centerer{
            .dialog-overflow{
                max-width: 270px;
                margin: auto;
                .dialog-box.logo{
                    text-align: center;
                    background: none;
                    border: 0;
                    box-shadow: none;
                    .close{
                        display: none;
                    }
                }
                
                
            }
        }
    }
 }


 @-webkit-keyframes anim-open {
	0% { opacity: 0; /*-webkit-transform: scale3d(0, 0, 1);*/ }
	100% { opacity: 1; /*-webkit-transform: scale3d(1, 1, 1);*/ }
}

@keyframes anim-open {
	0% { opacity: 0; /*-webkit-transform: scale3d(0, 0, 1); transform: scale3d(0, 0, 1);*/ }
	100% { opacity: 1; /*-webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1);*/ }
}

@-webkit-keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; /*-webki56t-transform: scale3d(0.5, 0.5, 1);*/ }
}

@keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; /*-webkit-transform: scale3d(0.5, 0.5, 1); transform: scale3d(0.5, 0.5, 1);*/ }
}

body{
    overflow: auto;
}
body.dialog-open{
    overflow: hidden;
}