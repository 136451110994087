.container{
    &.narrow{
        p{
            max-width: 950px;
        }
    }
}
.min-section-static{
    overflow: hidden;
    min-height: 1000px;
    position: relative;
    @media (min-width: 768px) {
        min-height: 1000px;
    }
}

.step-form{
    transition: all .3s ease-in-out;
    position: absolute;
    width: 100%;
    top: 5em;
    // right: -100%;
    // left: 0;
    transform: translateX(0%);
    h2{
        text-align: center;
    }
    p{
        text-align: center;
        font-size: 13px;
        line-height: 20px;
    }
    .button-dropbox{
        display: none;
    }
    .area-drag{
        width: 100%;
        text-align: center;
        &.uploading{
            &:before{
                display: none;
            }
            .progress_circle{
                top: 10px;
                left: 10px;
            }
        }
    }
    .pulses{
        // max-width: 175px;
        margin: 0 auto;
        .pulse{
            width: 250px;
            height: 250px;
            background: hsla(0,0%,100%,.02);
            border: 1px solid hsla(0,0%,100%,.3);
            border-radius: 50%;
            margin-left: 50%;
            left: -125px;
            position: absolute;
            bottom: -20px;
        }
        .gift-sticker{
            transform: scale(.83);
        }
    }
    &.hided{
        // right: -100%;
        // left: auto;
        transform: translateX(100%);
    }
    &.done{
        // right: auto;
        // left: -100%;
        transform: translateX(-100%);
    }
    &.active{
        // right: 0;
        // left: auto;
        transform: translateX(0%);
    }
    .row{
        .col{
            width: 100%;
            padding-right: 0;
        }
    }
}

.step-row{
    display: table;
    &>.col{
        display: table-cell;
        float: none;
        vertical-align: middle;
    }
    &:before, &:after{
        display: none;
    }
    .col-3{
        width: 25%;
    }
    .col-4{
        width: 35%;
        padding-left: 20px;
    }
    .col-8{
        width: 65%;
    }
    .col-9{
        width: 75%;
    }
}

.step-btns{
    float: none;
    display: block;
    margin-top: 15px;
    a{
       font-family: $font-seravek;
        font-weight: normal;
        font-size: 14px;
        color: $color-darker;
        margin-right: 20px;
        cursor: pointer;
    }
    .back{
        &:before{
            content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-arrow-back.svg);
            margin-right: 5px;
        }
    }
    .retry{
        &:before{
            content:url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/icon-retry.svg);
            margin-right: 5px;
            position: relative;
            top: 4px;
        }
    }
}