.nav-menu{
	position: fixed;
	bottom: 0;
	z-index: 1000;
	background-color: $color-white;
	width: 100%;
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	max-width:768px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 300ms ease-in;
	&.fadein{
		opacity: 1;
		visibility: visible;
	}
	ul{
		padding: 0;
		margin: 0;
		list-style: none;
		li{
			float: left;
			width: 20%;
			text-align: center;
			&.active{
				a{
					color: #000;
					.icon{
						&.icon_home{ background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/navmenu/1a.svg); }
						&.icon_services{ background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/navmenu/2a.svg); }
						&.icon_ourworks{ background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/navmenu/3a.svg); }
						&.icon_agency{ background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/navmenu/4a.svg); }
						&.icon_contact{ background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/navmenu/5a.svg); }
					}
				}
			}
			a{
				padding: 18.5px 0px;
				display: block;
				font-size: 12px;
				color: rgba(0, 0, 0, 0.38);
				font-weight: $font-regular;
				.icon{
					width: 25px;
					height: 25px;
					background-position: 0;
					background-repeat: no-repeat;
					display: block;
					background-size: 100%;
					margin: 0 auto;
					&.icon_home{ background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/navmenu/1.svg); }
					&.icon_services{ background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/navmenu/2.svg); }
					&.icon_ourworks{ background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/navmenu/3.svg); }
					&.icon_agency{ background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/navmenu/4.svg); }
					&.icon_contact{ background-image: url(https://d1w5h4dsqm651w.cloudfront.net/mobile/assets/images/icons/navmenu/5.svg); }
				}
			}
		}
	}
}