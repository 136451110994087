.cookies_popup{
	position: fixed;
	bottom: 80px;
	width: auto;
	background-color: #fff;
	padding: 12px;
	z-index: 110;
	right: 15px;
	left: 15px;
	border-radius: 4px;
	border-left: 2px solid $orange;
	box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
	transition: all .3s ease-in-out;
	opacity: 0;
	transform: translateY(-100%);
	@media (min-width: 450px){
		text-align: left;	
		left: auto;
	}
	&.animated{
		-webkit-animation-duration: .4s;
		animation-duration: .4s;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
		-webkit-animation-name: a;
		animation-name: a;
		-webkit-animation-timing-function: cubic-bezier(.6,0,.4,1);
		animation-timing-function: cubic-bezier(.6,0,.4,1);
	}
	&.addopacity{
		opacity: 1;
		z-index: 10;
		transform: translateY(0);
	}
	&.addopacity.closed{
		opacity: 0;
		z-index:-1;
	}
	p{
		margin: 0;
		display: inline-block;
		vertical-align: middle;
		font-size: 13px;
		margin-right: 10px;
		line-height: 1.4;
		max-width: calc(100% - 70px);
	}
	button{
		display: inline-block;
		vertical-align: middle;
		color: $color-white;
		background-color: $orange;
		border: none;
		border-radius: 4px;
		font-size: 14px;
		padding: 7px 12px;
		cursor: pointer;
	}
}