.tilter {
	position: relative;
	display: block;
	flex: none;
	width: 151.84px;
	height: 261.33px;
	margin: 0;
	color: #fff;
	perspective: 1000px;
	z-index: 10;
	@media (min-width: 768px){
		width: 191.84px;
		height: 301.33px;
	}
}

.tilter * {
	pointer-events: none;
}

.tilter:hover,
.tilter:focus {
	color: #fff;
	outline: none;
}

.tilter__figure,
.tilter__image {
	display: block;
	width: 100%;
	height: 100%;
	margin: 0;
}

.tilter__figure > * {
	transform: translateZ(0px); /* Force correct stacking order */
}

.tilter__figure {
	position: relative;
}

.tilter__figure::before {
	content: '';
	position: absolute;
	// top: 5%;
	// left: 5%;
	// width: 90%;
	// height: 90%;
	// // box-shadow: 0 30px 20px rgba(35,32,39,0.5);
	// box-shadow: 0 30px 20px rgba(0,0,0,0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 3px 20px rgba(0, 0, 0, 0.35);
}

.tilter__deco {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.tilter__deco--overlay {
	background-image: linear-gradient(45deg, rgba(226, 60, 99, 0.4), rgba(145, 58, 252, 0.4), rgba(16, 11, 192, 0.4));
	display: none;
}

.tilter__deco--shine div {
	position: absolute;
	top: -50%;
	left: -50%;
	width: 200%;
	height: 200%;
	background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0.25) 50%, transparent 100%);
}

.tilter__deco--lines {
	// fill: none;
	// stroke: #fff;
	// stroke-width: 1.5px;
	border: 1px solid #fff;
	top: 4%;
	left: 5%;
	width: 90%;
	height: 93%;
}

.tilter__caption {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 4em;
}

.tilter__title {
	font-family: 'Abril Fatface', serif;
	font-size: 2.5em;
	font-weight: normal;
	line-height: 1;
	margin: 0;
}

.tilter__description {
	font-size: 0.85em;
	margin: 1em 0 0 0;
	letter-spacing: 0.15em;
}